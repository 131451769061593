import React, { useState, useEffect, useRef } from 'react';
import { View, Text, Image, TouchableOpacity, FlatList, ActivityIndicator, StyleSheet, RefreshControl, TextInput, Clipboard, Platform } from 'react-native';
import ActiveTabItem from '../components/activeTabItem'
import { commonStyle } from '../components/style'
import { useTranslation } from 'react-i18next';
import { FontAwesome, MaterialIcons } from '@expo/vector-icons';
import { exchangesale, exchangeget,getWebsiteInfo } from '../api/apis';
import { transactionType, formatTimestampToDateTime, vipGrade, unitconversion, formatNumberAuth,formatTwoDigits } from '../components/util'
import Toast from 'react-native-easy-toast'

const App = (props) => {

    const currency = props?.route?.params?.currency;

    const { t } = useTranslation();
    const toastRef = useRef(null);
    const [data, setData] = useState([]);
    const [refreshing, setRefreshing] = useState(false);
    const [loadingMore, setLoadingMore] = useState(false);
    const [page, setPage] = useState(1);
    const [emptyShow, setEmptyShow] = useState(false);
    const [operatorType, setOperatorType] = useState(6); // 6：全部 7：买入 8：卖出
    const [recordCalled, setRecordCalled] = useState(false);
    const [disabledButton, setDisabledButton] = useState([]);
    const [handfeeRate, setHandfeeRate] = useState(0);


    useEffect(() => {
        init()
        handleRefresh()
    }, []);


    useEffect(() => {
        record();
    }, [page, operatorType, refreshing, loadingMore]);

    const init = async () => {
        const response = await getWebsiteInfo();
        if (response.status == '1') {
            if (response.Data.handfee_rate != '') {
                setHandfeeRate(Number(response.Data.handfee_rate))
            }
        }
       
    };

    // 刷新
    const handleRefresh = () => {
        setData([]);
        setPage(1)
        setRefreshing(true)
        if (Platform.OS == 'web') {
            setLoadingMore(true);
        }
    };

    // 加载更多数据
    const handleLoadMore = () => {
        setLoadingMore(true);
        setPage(parseInt(page) + 1)
    };


    const closePosition = (item) => {

        if (disabledButton[item.cd_id]) {
            return;
        }

        setDisabledButton(prevLoading => {
            const updatedLoading = [...prevLoading];
            updatedLoading[item.cd_id] = true;
            return updatedLoading;
        });

        const lotteryCode = (currency + 'usdt').toLowerCase()
        const param = { long_type: 1, lottery_code: lotteryCode, cd_id: item.cd_id }
        console.log(param)
        exchangesale(param).then(response => {
            toastRef.current.show(t('success'));
            if (response.status == 1) {
                setTimeout(() => {
                    handleRefresh()
                }, 2000);

            } else {
                toastRef.current.show(response.Data.msg);
                return;
            }
        }).catch(error => {

        }).finally(() => {
            setDisabledButton(prevLoading => {
                const updatedLoading = [...prevLoading];
                updatedLoading[item.cd_id] = false;
                return updatedLoading;
            });
        });

    };

    // 取消
    const cancelOrder = (id) => {

        exchangeget('', 3, id).then(response => {
            if (response?.status == 1) {
                toastRef.current.show(t('success'));
            } else {
                toastRef.current.show(t('fail'));
            }

            setTimeout(() => {
                handleRefresh()
            }, 2000);

        }).finally(() => {

        });
    }

    //获取记录
    const record = async () => {

        const lotteryCode = (currency + 'usdt').toLowerCase()

        await exchangeget(lotteryCode, operatorType, false, page, 6, 1).then(response => {
            setRefreshing(false);
            setLoadingMore(false);

            setEmptyShow(false)
            if(page == 1 && response == ''){
                setData([])
                setEmptyShow(true)
                return
            }

            if (response?.status == 1) {
                if (Array.isArray(response?.data)) {
                    const updateData = response.data.map(data => {
                        return { ...data, };
                    });
                    const dataList = data.concat(updateData)
                    if (page > 1) {
                        setData(dataList)
                    } else {
                        setData(updateData)
                        if (dataList.length == 0) {
                            setEmptyShow(true)
                        }
                    }
                } else {
                    setData([])
                    setEmptyShow(true)
                }
            }

        }).catch(error => {
            setRefreshing(false);
            setLoadingMore(false);
            // console.error(error);
        }).finally(() => {


        });
    };

    const renderItem = ({ item }) => (
        <View style={styles.itemContainer}>

            <View style={styles.itemRowContainer}>
                <View style={{ flexDirection: 'row' }}>
                    {item.up_down == 0 &&
                        <TouchableOpacity style={{ backgroundColor: commonStyle.green, padding: 1, borderRadius: 3 }}>
                            <Text style={{ color: '#fff', textAlign: 'center', fontSize: 10 }}>{t('openLong')}</Text>
                        </TouchableOpacity>
                    }
                    {item.up_down == 1 &&
                        <TouchableOpacity style={{ backgroundColor: commonStyle.red, padding: 1, borderRadius: 3 }}>
                            <Text style={{ color: '#fff', textAlign: 'center', fontSize: 10 }}>{t('openShort')}</Text>
                        </TouchableOpacity>
                    }
                    <Text style={{ color: '#fff', fontSize: 12 }}> {item.Lotterly_code} x{item.buy_number}{t('hand')} x{item.bet_muli}{t('multiple')}</Text>
                </View>
                <View>

                </View>
            </View>


            <View style={styles.itemRowContainer}>
                <View style={{ flexDirection: 'row' }}>
                    <Text style={{ color: '#fff' }}>{t('yieldRate')}</Text>
                </View>
                <View style={{ flexDirection: 'row' }}>
                    {Number(item.yieid_rate) * 100 > 0 && item.yieid_rate >= 0 && <Text style={styles.itemTextGreen}>{formatNumberAuth(item.yieid_rate * 100 
                    )}%</Text>}
                     {Number(item.yieid_rate) * 100 > 0 && item.yieid_rate < 0 && <Text style={styles.itemTextRed}>{formatNumberAuth(item.yieid_rate * 100 
                    )}%</Text>}

                    {Number(item.yieid_rate) * 100 < 0 && item.yieid_rate >= 0 && <Text style={styles.itemTextGreen}>{formatNumberAuth(item.yieid_rate * 100 
                    )}%</Text>}
                    {Number(item.yieid_rate) * 100 < 0 && item.yieid_rate < 0 && <Text style={styles.itemTextRed}>{formatNumberAuth(item.yieid_rate * 100 
                    )}%</Text>}

                    {Number(item.yieid_rate) * 100 == 0 && 
                    ((item.sale_price1 - (item.buy_price * item.buy_number)) / (item.buy_price * item.buy_number)) >= 0  &&
                    <Text style={styles.itemTextGreen}>{formatNumberAuth(((item.sale_price1 - (item.buy_price * item.buy_number)) / (item.buy_price * item.buy_number)) 
                    )}%</Text>}
                    {Number(item.yieid_rate) * 100 == 0 && 
                    ((item.sale_price1 - (item.buy_price * item.buy_number)) / (item.buy_price * item.buy_number)) < 0  &&
                    <Text style={styles.itemTextRed}>{formatNumberAuth(((item.sale_price1 - (item.buy_price * item.buy_number)) / (item.buy_price * item.buy_number)) 
                    )}%</Text>}
                </View>
            </View>

            <View style={styles.itemRowContainer}>
                <View style={{ flexDirection: 'row' }}>
                    {item.buy_sale == 5 && Number(item.yieid_rate) * 100 == 0 &&
                        <Text style={{ color: '#fff' }}>{t('unrealizedProfitAndLoss')}</Text>
                    }
                    {item.buy_sale == 5 && Number(item.yieid_rate) * 100 != 0 &&
                        <Text style={{ color: '#fff' }}>{t('unrealizedProfitAndLoss')}</Text>
                    }
                    {item.buy_sale == 6 && Number(item.yieid_rate) * 100 == 0 &&
                        <Text style={{ color: '#fff' }}>{t('agentReport.30')}</Text>
                    }
                    {item.buy_sale == 6 && Number(item.yieid_rate) * 100 != 0 &&
                        <Text style={{ color: '#fff' }}>{t('agentReport.30')}</Text>
                    }
                </View>
                <View style={{ flexDirection: 'row' }}>
                    {item.buy_sale == 5 && Number(item.yieid_rate) * 100 == 0 &&
                         <Text style={{ color: Number((item.sale_price1 - (item.buy_price * item.buy_number)) * item.bet_muli) >= 0 ? '#13B777' : '#F5465C' }}>{formatTwoDigits((item.sale_price1 - (item.buy_price * item.buy_number)) * item.bet_muli)}</Text>
                    }
                    {item.buy_sale == 5 && Number(item.yieid_rate) * 100 != 0 &&
                        <Text style={{ color: Number(item.yieid_price) >= 0 ? '#13B777' : '#F5465C' }}>{formatTwoDigits(item.yieid_price)}</Text>
                    }
                    {item.buy_sale == 6 && Number(item.yieid_rate) * 100 == 0 &&
                        <Text style={{ color: Number((item.sale_price1 - (item.buy_price * item.buy_number)) * item.bet_muli) >= 0 ? '#13B777' : '#F5465C' }}>{formatTwoDigits((item.sale_price1 - (item.buy_price * item.buy_number)) * item.bet_muli)}</Text>
                    }
                    {item.buy_sale == 6 && Number(item.yieid_rate) * 100 != 0 &&
                        <Text style={{ color: Number(item.yieid_price) >= 0 ? '#13B777' : '#F5465C' }}>{formatTwoDigits(item.yieid_price)}</Text>
                    }
                </View>
            </View>


            <View style={styles.itemRowContainer}>
                <View>
                    <Text style={{ color: '#fff' }}>{t('openPositionPrice')}</Text>
                </View>
                <View>
                    {item.buy_price > 0 && <Text style={{ color: '#fff' }}>{item.buy_price}</Text>}
                </View>
            </View>
            <View style={styles.itemRowContainer}>
                <View>
                    <Text style={{ color: '#fff' }}>{t('margin')}</Text>
                </View>
                <View>
                    {item.buy_price > 0 && <Text style={{ color: '#fff' }}>{formatTwoDigits(item.bzj_money)}</Text>}
                </View>
            </View>

            <View style={styles.itemRowContainer}>
                <View>
                    <Text style={{ color: '#fff' }}>{t('handfee')}</Text>
                </View>
                <View>
                    {Number(item.yieid_rate) * 100 > 0 && <Text style={styles.itemTextWhite}>{formatNumberAuth((item.yieid_rate * 100 * item.bet_muli) * item.bzj_money * handfeeRate)}</Text>}
                    {Number(item.yieid_rate) * 100 < 0 && <Text style={styles.itemTextWhite}>{formatNumberAuth((item.yieid_rate * 100 * item.bet_muli) * item.bzj_money * handfeeRate)}</Text>}
                    {Number(item.yieid_rate) * 100 == 0 && <Text style={styles.itemTextWhite}>{formatNumberAuth((((item.sale_price1 - (item.buy_price * item.buy_number)) / (item.buy_price * item.buy_number)) * item.bet_muli) * item.bzj_money * handfeeRate)}</Text>}
                </View>
            </View>

            <View style={styles.itemRowContainer}>
                <View>
                    <Text style={{ color: '#fff' }}>{t('openTime')}</Text>
                </View>
                <View>
                    <Text style={{ color: '#fff' }}>
                        {formatTimestampToDateTime(item.buy_time / 1000)}
                    </Text>
                </View>
            </View>

            <View style={styles.itemRowContainer}>
                <View>
                    <Text style={{ color: '#fff' }}>{t('closingTime')}</Text>
                </View>
                <View>
                    <Text style={{ color: '#fff' }}>
                        {formatTimestampToDateTime(item.sale_time / 1000)}
                    </Text>
                </View>
            </View>



            <View >
                <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                    <View>
                        {item.buy_status == 0 &&
                            <TouchableOpacity style={{ backgroundColor: commonStyle.red, width: 100, padding: 3, borderRadius: 5 }} onPress={() => { cancelOrder(item.cd_id) }}>
                                <Text style={{ color: '#fff', textAlign: 'center', }}>{t('cancel')}</Text>
                            </TouchableOpacity>
                        }
                    </View>
                    {(item.buy_sale == 5 && item.buy_status == 1) &&
                        <TouchableOpacity disabled={disabledButton[item.cd_id]} onPress={() => closePosition(item)} style={{ backgroundColor: commonStyle.green, width: 100, padding: 3, borderRadius: 5 }}>
                            <Text style={{ color: '#fff', textAlign: 'center', }}>{t('closePosition')}</Text>
                        </TouchableOpacity>}
                </View>
            </View>

        </View>



    );

    return (
        <View style={styles.container}>

            {/* <Image style={styles.image} source={require('../../assets/imgs/common/empty.png')} /> */}

            <View style={{ flexDirection: 'row', justifyContent: 'space-around', marginBottom: 10 }}>
                <TouchableOpacity style={[operatorType == 6 && styles.tabActive, { borderBottomColor: commonStyle.green, padding: 5, flex: 1 }]} onPress={() => { setOperatorType(6); handleRefresh() }} >
                    <Text style={{ color: '#fff', fontSize: 16, textAlign: 'center' }}>{t('all')}</Text>
                </TouchableOpacity>
                <TouchableOpacity style={[operatorType == 7 && styles.tabActive, { borderBottomColor: commonStyle.green, padding: 5, flex: 1 }]} onPress={() => { setOperatorType(7); handleRefresh() }} >
                    <Text style={{ color: '#fff', fontSize: 16, textAlign: 'center' }}>{t('openLong')}</Text>
                </TouchableOpacity>
                <TouchableOpacity style={[operatorType == 8 && styles.tabActive, { borderBottomColor: commonStyle.green, padding: 5, flex: 1 }]} onPress={() => { setOperatorType(8); handleRefresh() }} >
                    <Text style={{ color: '#fff', fontSize: 16, textAlign: 'center' }}>{t('openShort')}</Text>
                </TouchableOpacity>
            </View>

            <FlatList
                data={data}
                renderItem={renderItem}
                keyExtractor={(item, index) => index}
                refreshControl={<RefreshControl refreshing={refreshing} onRefresh={handleRefresh} />}
                onEndReached={!loadingMore && handleLoadMore}
                onEndReachedThreshold={0.1}
                ListFooterComponent={loadingMore && <ActivityIndicator color={commonStyle.green} />}
            />

            {emptyShow && <View style={{ justifyContent: 'center', alignItems: 'center', height: '80%' }}>
                <Text style={{ color: '#fff' }}>{t('noData')}</Text>
            </View>}

            <Toast
                ref={toastRef}
                style={{ backgroundColor: 'gray' }}
                position='top'
                positionValue={200}
                textStyle={{ color: '#fff' }}
            />
        </View>
    );
};

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: commonStyle.bgColor
    },
    tabContainer: {
        flexDirection: 'row',
        paddingVertical: 10,
        backgroundColor: '#1B1D29',
    },
    tabItem: {
        flex: 1,
        alignItems: 'center',
        paddingVertical: 10,
    },
    tabText: {
        color: commonStyle.textGreenColor,
    },
    itemTextGreen: {
        color: commonStyle.textGreenColor,
    },
    itemTextYellow: {
        color: commonStyle.yellow,
    },
    itemTextRed: {
        color: commonStyle.textRedColor,
    },
    itemTextGray: {
        color: commonStyle.textGrayColor
    },
    itemTextWhite: {
        color: commonStyle.textWhiteColor
    },
    itemContainer: {
        flexDirection: "column",
        paddingVertical: 10,
        paddingHorizontal: 20,
        borderBottomWidth: 1,
        backgroundColor: '#1B1D29',
        marginTop: 5,
    },
    itemRowContainer: {
        flexDirection: "row",
        justifyContent: "space-between",
        marginBottom: 4
    },
    activeTabItem: {
        justifyContent: 'center',
        alignItems: 'center'
    },
    input: {
        borderWidth: 0,
        borderBottomColor: 'transparent',
        color: '#fff',
        borderRadius: 2,
        padding: 5,
        width: '90%',
    },
    tabActive: {
        borderBottomWidth: 2
    }
});

export default App;