import React, { useState, useEffect, useRef, useImperativeHandle, forwardRef } from 'react';

import { View, Text, TextInput, StyleSheet, TouchableOpacity, Modal, ScrollView, Image, ActivityIndicator } from 'react-native';
import { useNavigation, useFocusEffect } from '@react-navigation/native';
import { useTranslation } from 'react-i18next';
import { commonStyle } from '../components/style';
import SpliteLine from '../components/spliteLine';
import { getWebsiteInfo, getMarketDepth, getHuobiPrice, exchangesale, exchange, exchangeget, getUserbalance, binanceDepth, binanceTickerPrice, coinPrice, exchangerate } from '../api/apis';
import { showAlert } from '../components/showAlert';
import AsyncStorage from '@react-native-community/async-storage';
import { formatTimestampToDateTime, unitconversion, navigateRequireLogin, virtual2, validateAmount, formatNumberAuth, formatTwoDigits } from '../components/util'
import { Entypo, AntDesign, EvilIcons } from '@expo/vector-icons';
import Toast from 'react-native-easy-toast'
import { color } from 'react-native-elements/dist/helpers';

import Depth from './depth';

// import { Feather } from '@expo/vector-icons';

const App = forwardRef((props, ref) => {
    // const App = () => {
    const navigation = useNavigation();
    const { t } = useTranslation();
    const toastRef = useRef(null);

    const [newCurrency, setNewCurrency] = useState('');

    const [currency, setCurrency] = useState('BTC');
    const [upDown, setUpDown] = useState('up');
    const [curr, setCurr] = useState('');
    const [amount, setAmount] = useState(0);
    const [currentPrice, setCurrentPrice] = useState(0);

    const [num, setNum] = useState(''); //数量

    const [modalVisible, setModalVisible] = useState(false);
    const [modalVisible2, setModalVisible2] = useState(false);
    const [entrustType, setEntrustType] = useState(1); // 1限价委托 2市价委托
    const [transactionVolume, setTransactionVolume] = useState(0);
    const [userbalance, setUserbalance] = useState(0); // 余额
    const [currencyAmount, setCurrencyAmount] = useState(0); //币种余额
    const [lever, setLever] = useState(1); // 杠杆

    const [order, setOrder] = useState([]);
    const [emptyShow, setEmptyShow] = useState(false);
    const [isSubmit, setIsSubmit] = useState(false);
    const [loadingMore, setLoadingMore] = useState(false);
    const [headerRightButton, setHeaderRightButton] = useState('none');
    const [disabledButton, setDisabledButton] = useState([]);
    const [muliIs, setMuliIs] = useState(false);
    const [margPer, setMargPer] = useState([]);
    const [margPerVal, setMargPerVal] = useState(false);  // 手数
    const [handfeeRate, setHandfeeRate] = useState(0);


    // useEffect(() => {

    //     const intervalId = setInterval(weituoList, 2000);
    //     return () => {
    //         clearInterval(intervalId);
    //     };

    // }, [order]);


    useFocusEffect(
        React.useCallback(() => {
            const intervalId = setInterval(weituoList, 5000);
            return () => {
                clearInterval(intervalId);
            };
        }, [order])
    );

    const isLogin = async (navigation) => {
        try {
            const userinfo = await AsyncStorage.getItem('userinfo');
            if (!userinfo) {
                navigation.navigate('Login');
                console.log('未登录');
            } else {
                console.log('已登录');
            }
        } catch (error) {
            console.error('读取 AsyncStorage 失败', error);
        }
    };

    // useEffect(() => {
    //     const socket = new WebSocket('wss://127.0.0.1');

    //     socket.onopen = () => {
    //         console.log('WebSocket connection established');
    //     };

    //     socket.onmessage = (event) => {
    //         const data = JSON.parse(event.data);
    //         console.log(data)
    //     };

    //     socket.onclose = () => {
    //         console.log('WebSocket connection closed');
    //     };

    //     return () => {
    //         socket.close(); // 清理 WebSocket 连接
    //     };
    // }, []);


    // React.useLayoutEffect(() => {
    //     navigation.setOptions({
    //         title: currency,
    //         headerStyle: {
    //             backgroundColor: commonStyle.bgColor,
    //             borderBottomWidth: 0
    //         },
    //         headerTitleStyle: {}
    //     });
    // }, [navigation]);

    // useEffect(() => {

    //     init();

    // }, [currency]);



    React.useImperativeHandle(ref, () => ({
        goToExchangeScreen,
    }));

    // const handleHeaderRightClick = () => {
    //     if (headerRightButton == 'none') {
    //         setHeaderRightButton('block')
    //     } else {
    //         setHeaderRightButton('none')
    //     }
    // };

    // React.useLayoutEffect(() => {

    //     navigation.setOptions({
    //         headerLeft: () => (
    //             <TouchableOpacity style={{flexDirection:'row'}} onPress={() => handleHeaderRightClick()}>
    //                 <Feather name="list" size={24} color='#fff' />
    //                 <Text style={{color:'#fff',marginLeft:5,fontSize:18}}>{currency}</Text>
    //             </TouchableOpacity>
    //         ),
    //         headerRight: () => (
    //             <View>
    //                 <TouchableOpacity onPress={() => {navigation.navigate('Chart',{currency:currency,prevName:'ConfirmOrder'})}}>
    //                     <Image style={{width:30,height:30}} source={require('../../assets/imgs/contract/tab.png')} />
    //                 </TouchableOpacity>
    //             </View>
    //         ),
    //     });
    // }, [navigation, headerRightButton]);


    const init = async () => {
        const lotteryCode = (currency + 'usdt').toLowerCase()

        // console.log('参数',props.currency)
        // setCurrency(props.currency)
        setOrder([])
        setAmount(0);

        // 开多
        exchangeget(lotteryCode, 7, false, 1).then(response => {

        }).catch(error => { })

        // 开空
        exchangeget(lotteryCode, 8, false, 1).then(response => {

        }).catch(error => { })


        const response = await getWebsiteInfo();
        if (response.status == '1') {
            setCurr(response?.Data?.curr)

            // 测试
            //response.Data.muli_is = 5

            setMuliIs(response?.Data?.muli_is)
            if (response?.Data?.muli_is == 1) {
                setLever(1)
            } else {
                setLever(5)
            }

            if (response.Data.marg_per != '') {
                setMargPer(response.Data.marg_per.split(","));
            }

            if (response.Data.handfee_rate != '') {
                setHandfeeRate(Number(response.Data.handfee_rate))
            }


        }


        exchangeget(lotteryCode).then(response => {
            // console.log('持仓数据')
            // console.log(response.count)
            setCurrencyAmount(response?.count)
        });


        getUserbalance().then(response => {
            if (response.status == 1) {
                setUserbalance(response?.Data?.BackData)
                // const coin = response?.Data?.other;
                // if (coin.hasOwnProperty(data.currency)) {
                //     const value = coin[data.currency];
                //     setCurrencyAmount(value)
                // }
            }
        }).catch(error => {
            // console.error(error);
        });

        getPrice()
        weituoList()
    };

    useFocusEffect(
        React.useCallback(() => {
            isLogin(navigation)
            init()
            // weituoList()
        }, [currency])
    );

    const getPrice = async () => {
        console.log('获取价格')
        if (Object.values(virtual2()).includes(currency)) {
            await coinPrice(currency).then(response => {
                setAmount(response?.price)
                setCurrentPrice(response?.price);
            });
            // binanceTickerPrice(currency + 'USDT').then(response => {
            //     setAmount(response.price)
            //     setCurrentPrice(response.price);
            // });
        } else {
            const huobiPriceResponse = await getHuobiPrice(currency);
            if (huobiPriceResponse) {
                setCurrentPrice(huobiPriceResponse);
                setAmount(huobiPriceResponse)
            }
        }
    };


    const weituoList = async () => {
        setLoadingMore(true)
        // setEmptyShow(false)

        console.log('委托单' + currency)
        const lotteryCode = (currency + 'usdt').toLowerCase()
        await exchangeget(lotteryCode, 6, false, 1, 5, 1).then(response => {
            setLoadingMore(false)
            if (Array.isArray(response?.data)) {
                setOrder(response?.data)
                if (response?.data.length == 0) {
                    setEmptyShow(true)
                } else {
                    setEmptyShow(false)
                }
            } else {
                setOrder([])
                setEmptyShow(true)
            }

        }).catch(error => {
            setOrder([])
            setLoadingMore(false)
            // console.error(error);
        })


        // getWeituoList().then(response => {
        //     console.log('委托单')
        //     console.log(response)
        //     if (response.status == 1) {

        //     }
        // }).catch(error => {
        //     console.log('委托单err')
        //     // console.error(error);
        // });

    };


    const handleAmountChanged = (text) => {
        if (!validateAmount(text)) {
            return;
        }
        setAmount(text);

    };

    // 选择数量
    const handleNumChanged = (text) => {
        if (!validateAmount(text)) {
            return;
        }

        let betting_price
        if (entrustType == 0) {
            betting_price = amount

        } else {
            betting_price = currentPrice
        }

        if (!(betting_price > 0)) {
            return
        }
        setNum(text);

        setTransactionVolume(betting_price * text * lever)

        setMargPerVal(false)
    };

    const handleUpDown = (direction) => {
        setNum('')
        setUpDown(direction);
    };

    const openModal = (type) => {
        setModalVisible(true);
    };
    const openModal2 = (type) => {
        setModalVisible2(true);
    };
    const closeModal = () => {
        setModalVisible(false);
        setModalVisible2(false);
    };

    const handleEntrustType = (type) => {
        setEntrustType(type);
        setAmount('')
        setNum('')
        setMargPerVal(false)
        closeModal()
    };

    // 选择杠杆
    const handleLever = (val) => {
        setLever(val);
        closeModal()

        let betting_price
        if (entrustType == 0) {
            betting_price = amount

        } else {
            betting_price = currentPrice
        }

        if (!(betting_price > 0)) {
            return;
        }

        setTransactionVolume(betting_price * num / val)
        setMargPerVal(false)
        setNum('')
    };

    const handleSubmit = () => {

        if (isSubmit) {
            return;
        }

        let betting_price;
        if (entrustType == 0) {
            betting_price = amount

        } else {
            betting_price = currentPrice
        }

        if (!(betting_price > 0)) {
            return;
        }

        if (!(num > 0)) {
            return;
        }

        const lotteryCode = (currency + 'usdt').toLowerCase()
        let param = { handfee_rate: handfeeRate, long_type: 1, buy_updow: 0, lottery_code: lotteryCode, bet_type: entrustType, betting_price: betting_price, betting_count: num, buy_muli: lever, bzj_money: formatTwoDigits(transactionVolume) }
        console.log(param)

        setIsSubmit(true)

        if (upDown == 'down') {
            param.buy_updow = 1
        }

        exchange(param).then(response => {

            if (response?.status == 1) {
                setNum('')
                setIsSubmit(false)

                setTimeout(() => {
                    toastRef.current.show(t('success'));
                }, 3000);
                init()


                // setTimeout(() => {
                //     console.log('3');
                //     weituoList();
                // }, 3000);

                // setTimeout(() => {
                //     console.log('5');
                //     weituoList();
                // }, 5000);

                // setTimeout(() => {
                //     console.log('10');
                //     weituoList();
                // }, 10000);

                // navigation.navigate('BetRecord')
            } else {
                setIsSubmit(false)
                toastRef.current.show(response.Data.msg);
                return;
            }
        }).catch(error => {
            setIsSubmit(false)
            // toastRef.current.show('err');
            // return;
            // console.error(error);
        });

    };


    // 平仓
    const closePosition = (item) => {
        if (disabledButton[item.cd_id]) {
            return;
        }
        setDisabledButton(prevLoading => {
            const updatedLoading = [...prevLoading];
            updatedLoading[item.cd_id] = true;
            return updatedLoading;
        });

        const lotteryCode = (currency + 'usdt').toLowerCase()
        const param = { long_type: 1, lottery_code: lotteryCode, cd_id: item.cd_id, bet_muli: item.bet_muli, handfee_rate: handfeeRate }
        console.log(param)
        exchangesale(param).then(response => {
            if (response.status == 1) {
                toastRef.current.show(t('success'));
                weituoList();
                setTimeout(() => {
                    weituoList();
                }, 3000);

                setTimeout(() => {
                    weituoList();
                }, 5000);

                setTimeout(() => {
                    weituoList();
                }, 10000);

            } else {
                toastRef.current.show(response.Data.msg);
                return;
            }
        }).catch(error => {

        }).finally(() => {
            setDisabledButton(prevLoading => {
                const updatedLoading = [...prevLoading];
                updatedLoading[item.cd_id] = false;
                return updatedLoading;
            });
        });

    };


    // 取消
    const cancelOrder = (id) => {

        exchangeget('', 3, id).then(response => {
            if (response?.status == 1) {
                toastRef.current.show(t('success'));
                init();
            } else {
                toastRef.current.show(t('fail'));
            }
            weituoList();
            // setTimeout(() => {
            //     console.log('3');
            //     weituoList();
            // }, 3000);

            // setTimeout(() => {
            //     console.log('5');
            //     weituoList();
            // }, 5000);

            // setTimeout(() => {
            //     console.log('10');
            //     weituoList();
            // }, 10000);

        }).finally(() => {

        });
    }

    const goToConfirmOrderScreen = (direction) => {

        const route = {
            name: 'ConfirmOrder2',
            params: { currency: currency, upDown: direction },
        };
        navigateRequireLogin(navigation, route);

    };

    const goToExchangeScreen = (currencyVal) => {
        // alert(currencyVal)

        setHeaderRightButton('none')
        setCurrency(currencyVal)
        setNum('')
    }

    // 选择手数
    const handleMargPer = (val) => {
        let betting_price
        if (entrustType == 0) {
            betting_price = amount
        } else {
            betting_price = currentPrice
        }
        if (!(betting_price > 0)) {
            return;
        }
        setMargPerVal(val)
        let numVal = (userbalance / 100) * val / betting_price
        numVal = numVal.toFixed(8)
        setNum((numVal / lever).toFixed(8))

        setTransactionVolume(betting_price * numVal)
    }

    const handleCurrencyChange = (val) => {

        // if(newCurrency > 0 && currentPrice != newCurrency){
        //     alert(newCurrency)
        // }

        // console.log('Currency changed to:', newCurrency);
        if (val > 0 && newCurrency != val) {
            setNewCurrency(val)
            // console.log('刷新')
            // getPrice()
            // weituoList()
        }

    };

    const BottomButtons = () => {
        return (
            <View style={styles.bottomContainer}>
                <TouchableOpacity style={styles.button} onPress={() => goToConfirmOrderScreen('up')}>
                    <View style={styles.buttonLeft}>
                        <Text style={styles.bottomText}>{t('buyUp')}</Text>
                    </View>
                </TouchableOpacity>
                <TouchableOpacity style={[styles.button]} onPress={() => goToConfirmOrderScreen('down')}>
                    <View style={styles.buttonRight}>
                        <Text style={styles.bottomText}>{t('buyDown')}</Text>
                    </View>
                </TouchableOpacity>
            </View>
        );
    };

    return (
        <View style={styles.container}>


            {/* <View style={{ display: headerRightButton, backgroundColor: 'rgba(18, 20, 32, 1)', borderRadius: 3, borderWidth: 1, borderColor: 'gray', width: 140, position: 'absolute', top: 0, left: 5, zIndex: 999 }}>
                    <View style={{ flexDirection: 'row', flexWrap: 'wrap' }}>
                        {Object.entries(virtual()).map(([symbol, name]) => (
                            <View key={name}>
                                  <TouchableOpacity onPress={() => goToExchangeScreen(symbol)}>
                                    <View style={{ width: 68 }}>
                                        <Text style={{ textAlign: 'center', color: '#fff', marginVertical: 10 }}>{symbol}</Text>
                                    </View>
                                </TouchableOpacity>
                            </View>
                        ))}
                    </View>
                </View> */}

            <ScrollView
                showsVerticalScrollIndicator={false}
                showsHorizontalScrollIndicator={false}

            >
                {/* <View>
                    <Text style={{ color: '#fff', fontSize: 16, fontWeight: 600 }}>{currency}/USD</Text>
                </View> */}
                {/* <SpliteLine /> */}
                <View style={{ marginTop: 0 }}>
                    <View style={{ flexDirection: 'row', justifyContent: 'space-between', marginBottom: 0 }}>
                        <View style={{ flex: 1 }}>
                            <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                                <TouchableOpacity onPress={() => handleUpDown('up')} style={{ flex: 1 }}>
                                    <Text style={{ textAlign: 'center', color: '#fff', backgroundColor: commonStyle.green, ...styles.button }}>
                                        {t('openLong')}
                                    </Text>
                                </TouchableOpacity>
                                <TouchableOpacity onPress={() => handleUpDown('down')} style={{ flex: 1 }}>
                                    <Text style={{ textAlign: 'center', color: '#fff', backgroundColor: commonStyle.red, ...styles.button }}>
                                        {t('openShort')}
                                    </Text>
                                </TouchableOpacity>
                            </View>

                            <View>

                                <View style={{ flexDirection: 'row', justifyContent: 'space-around', marginBottom: 10 }}>
                                    <TouchableOpacity style={[{ borderBottomWidth: 2, borderBottomColor: '#707070', padding: 5, flex: 1, marginRight: 4 }, entrustType == 1 && styles.tabActive]} onPress={() => { handleEntrustType(1); }} >
                                        <Text style={[{ color: '#707070', fontSize: 14, textAlign: 'center' }, , entrustType == 1 && styles.entrustTextActive]}>{t('marketOrder')}</Text>
                                    </TouchableOpacity>
                                    <TouchableOpacity style={[{ borderBottomWidth: 2, borderBottomColor: '#707070', padding: 5, flex: 1 }, entrustType == 0 && styles.tabActive]} onPress={() => { handleEntrustType(0); }} >
                                        <Text style={[{ color: '#707070', fontSize: 14, textAlign: 'center' }, entrustType == 0 && styles.entrustTextActive]}>{t('limitOrder')}</Text>
                                    </TouchableOpacity>

                                </View>


                                {/* <TouchableOpacity onPress={openModal}>
                                    <View style={styles.dropdownContainer}>
                                        {entrustType == 0 && <Text style={styles.dropdownText}>{t('limitOrder')}</Text>}
                                        {entrustType == 1 && <Text style={styles.dropdownText}>{t('marketOrder')}</Text>}
                                        <AntDesign name="down" size={16} color="#707070" />
                                        
                                    </View>
                                </TouchableOpacity> */}

                                {muliIs != 1 && lever != 1 && <TouchableOpacity onPress={openModal2}>
                                    <View style={styles.dropdownContainer}>
                                        <Text style={styles.dropdownText}>{lever}X</Text>
                                        <AntDesign name="down" size={16} color="#707070" />
                                    </View>
                                </TouchableOpacity>}

                                <View>
                                    <Text style={{ color: '#fff' }}>{t('numberLotsTrade')}</Text>
                                </View>
                                <View style={{ flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'flex-start', marginBottom: 5 }}>

                                    {margPer.map((item, index) => (
                                        <TouchableOpacity key={index} onPress={() => handleMargPer(item)}>
                                            <View style={[margPerVal && margPerVal == item && { backgroundColor: commonStyle.green }, { alignItems: 'center', width: 52, borderWidth: 1, borderColor: '#fff', borderRadius: 5, marginRight: 4, marginTop: 5 }]}><Text style={{ color: '#fff' }}>{item * 100}%</Text></View>
                                        </TouchableOpacity>
                                    ))}
                                </View>

                                {entrustType == 0 &&
                                    <TextInput
                                        style={styles.input}
                                        placeholder={t('amount')}
                                        onChangeText={handleAmountChanged}
                                        keyboardType="numeric"
                                        value={amount.toString()}
                                        placeholderTextColor="#707070"
                                    />}

                                <TextInput
                                    style={styles.input}
                                    placeholder={t('quantity')}
                                    onChangeText={handleNumChanged}
                                    keyboardType="numeric"
                                    value={num.toString()}
                                    placeholderTextColor="#707070"
                                />


                                {/* <Text style={{ color: '#fff', marginTop: 10 }}>{t('accountBalance')}: {upDown == 'up' ? unitconversion(userbalance, curr) + ' ' + curr : currencyAmount + ' ' + currency}</Text> */}

                                <Text style={{ color: '#fff', marginTop: 10, fontSize: 13 }}>{t('accountBalance')}: {unitconversion(userbalance, curr)} {curr}T</Text>
                                <Text style={{ color: '#fff', marginTop: 10, fontSize: 13 }}>{t('margin')}: {formatTwoDigits(transactionVolume)} USDT</Text>

                                {/* {amount > 0 && <Text style={{ color: '#fff', marginTop: 10, fontSize: 13 }}>{t('buyable')}: {(userbalance / amount).toFixed(4)} {currency}</Text>} */}

                                {/* <Text style={{ color: '#fff', marginTop: 10 }}>{t('transactionVolume')}: {transactionVolume.toFixed(4)} USDT</Text> */}

                                <TouchableOpacity onPress={handleSubmit}>
                                    <Text style={{ ...styles.button, backgroundColor: upDown === 'up' ? commonStyle.green : commonStyle.red, marginTop: 10, textAlign: 'center', color: '#fff' }}>
                                        {upDown === 'up' ? t('openLong') : t('openShort')}
                                    </Text>
                                </TouchableOpacity>
                            </View>
                        </View>

                        <Depth currency={currency} onCurrencyChange={handleCurrencyChange} />


                    </View>

                    <View style={{ marginTop: 5, marginBottom: 10, flexDirection: 'row', justifyContent: 'space-between' }}>
                        <View style={{ flexDirection: 'row' }}>
                            <Text style={{ color: '#fff' }}>{t('position')}</Text>
                            <TouchableOpacity style={{ flexDirection: 'row', marginLeft: 5, marginTop: 3 }} onPress={() => weituoList()}>
                                <AntDesign name="reload1" size={14} color="#fff" />
                            </TouchableOpacity>
                        </View>
                        <TouchableOpacity style={{ flexDirection: 'row' }} onPress={() => { navigation.navigate('ContractOrder', { currency: currency }) }}>
                            <EvilIcons name="navicon" size={21} color="#fff" />
                            <Text style={{ color: '#fff' }}>{t('history')}</Text>
                        </TouchableOpacity>



                    </View>

                    <SpliteLine />


                    <View style={{}}>

                        {/* {loadingMore && <ActivityIndicator color={commonStyle.green} style={{ marginTop: 20 }} />} */}

                        {order.length == 0 &&
                            <View>
                                <Text style={{ color: commonStyle.textGrayColor, textAlign: 'center', marginTop: 20 }}>{t('noData')}</Text>
                            </View>
                        }

                        {order.map((item, index) => (
                            <View key={index} style={styles.itemContainer}>

                                <View style={styles.itemRowContainer}>
                                    <View style={{ flexDirection: 'row' }}>
                                        {item.up_down == 0 &&
                                            <TouchableOpacity style={{ backgroundColor: commonStyle.green, padding: 1, borderRadius: 3 }}>
                                                <Text style={{ color: '#fff', textAlign: 'center', fontSize: 10 }}>{t('openLong')}</Text>
                                            </TouchableOpacity>
                                        }
                                        {item.up_down == 1 &&
                                            <TouchableOpacity style={{ backgroundColor: commonStyle.red, padding: 1, borderRadius: 3 }}>
                                                <Text style={{ color: '#fff', textAlign: 'center', fontSize: 10 }}>{t('openShort')}</Text>
                                            </TouchableOpacity>
                                        }
                                        <Text style={{ color: '#fff', fontSize: 12 }}> {item.Lotterly_code} x{item.buy_number}{t('hand')} x{item.bet_muli}{t('multiple')}</Text>
                                    </View>
                                    <View>

                                    </View>
                                </View>


                                <View style={styles.itemRowContainer}>
                                    <View style={{ flexDirection: 'row' }}>
                                        <Text style={{ color: '#fff' }}>{t('yieldRate')}</Text>
                                    </View>
                                    <View style={{ flexDirection: 'row' }}>
                                        {Number(item.yieid_rate) * 100 > 0 && item.yieid_rate >= 0 && <Text style={styles.itemTextGreen}>{formatNumberAuth(item.yieid_rate * 100
                                        )}%</Text>}
                                        {Number(item.yieid_rate) * 100 > 0 && item.yieid_rate < 0 && <Text style={styles.itemTextRed}>{formatNumberAuth(item.yieid_rate * 100
                                        )}%</Text>}

                                        {Number(item.yieid_rate) * 100 < 0 && item.yieid_rate >= 0 && <Text style={styles.itemTextGreen}>{formatNumberAuth(item.yieid_rate * 100
                                        )}%</Text>}
                                        {Number(item.yieid_rate) * 100 < 0 && item.yieid_rate < 0 && <Text style={styles.itemTextRed}>{formatNumberAuth(item.yieid_rate * 100
                                        )}%</Text>}

                                        {Number(item.yieid_rate) * 100 == 0 &&
                                            ((item.sale_price1 - (item.buy_price * item.buy_number)) / (item.buy_price * item.buy_number)) >= 0 &&
                                            <Text style={styles.itemTextGreen}>{formatNumberAuth(((item.sale_price1 - (item.buy_price * item.buy_number)) / (item.buy_price * item.buy_number))
                                            )}%</Text>}
                                        {Number(item.yieid_rate) * 100 == 0 &&
                                            ((item.sale_price1 - (item.buy_price * item.buy_number)) / (item.buy_price * item.buy_number)) < 0 &&
                                            <Text style={styles.itemTextRed}>{formatNumberAuth(((item.sale_price1 - (item.buy_price * item.buy_number)) / (item.buy_price * item.buy_number))
                                            )}%</Text>}
                                    </View>
                                </View>

                                <View style={styles.itemRowContainer}>
                                    <View style={{ flexDirection: 'row' }}>
                                        {item.buy_sale == 5 && Number(item.yieid_rate) * 100 == 0 &&
                                            <Text style={{ color: '#fff' }}>{t('unrealizedProfitAndLoss')}</Text>
                                        }
                                        {item.buy_sale == 5 && Number(item.yieid_rate) * 100 != 0 &&
                                            <Text style={{ color: '#fff' }}>{t('unrealizedProfitAndLoss')}</Text>
                                        }
                                        {item.buy_sale == 6 && Number(item.yieid_rate) * 100 == 0 &&
                                            <Text style={{ color: '#fff' }}>{t('agentReport.30')}</Text>
                                        }
                                        {item.buy_sale == 6 && Number(item.yieid_rate) * 100 != 0 &&
                                            <Text style={{ color: '#fff' }}>{t('agentReport.30')}</Text>
                                        }
                                    </View>
                                    <View style={{ flexDirection: 'row' }}>
                                        {item.buy_sale == 5 && Number(item.yieid_rate) * 100 == 0 &&
                                            <Text style={{ color: Number((item.sale_price1 - (item.buy_price * item.buy_number)) * item.bet_muli) >= 0 ? '#13B777' : '#F5465C' }}>{formatTwoDigits((item.sale_price1 - (item.buy_price * item.buy_number)) * item.bet_muli)}</Text>
                                        }
                                        {item.buy_sale == 5 && Number(item.yieid_rate) * 100 != 0 &&
                                            <Text style={{ color: Number(item.yieid_price) >= 0 ? '#13B777' : '#F5465C' }}>{formatTwoDigits(item.yieid_price)}</Text>
                                        }
                                        {item.buy_sale == 6 && Number(item.yieid_rate) * 100 == 0 &&
                                            <Text style={{ color: Number((item.sale_price1 - (item.buy_price * item.buy_number)) * item.bet_muli) >= 0 ? '#13B777' : '#F5465C' }}>{formatTwoDigits((item.sale_price1 - (item.buy_price * item.buy_number)) * item.bet_muli)}</Text>
                                        }
                                        {item.buy_sale == 6 && Number(item.yieid_rate) * 100 != 0 &&
                                            <Text style={{ color: Number(item.yieid_price) >= 0 ? '#13B777' : '#F5465C' }}>{formatTwoDigits(item.yieid_price)}</Text>
                                        }
                                    </View>
                                </View>

                                {/* <View style={styles.itemRowContainer}>
                                    <View style={{ flexDirection: 'row' }}>
                                        <Text style={{color:'#fff'}}>{item.order_number}</Text>
                                    </View>
                                    <View>
                                        {item.buy_status == 0 &&
                                            <Text style={styles.itemTextYellow}>{t('toBeTraded')}</Text>
                                        }
                                        {(item.buy_status == 1) &&
                                            <Text style={styles.itemTextGreen}>{t('tradeSuccessful')}</Text>
                                        }
                                        {item.buy_status == 2 &&
                                            <Text style={styles.itemTextRed}>{t('canceled')}</Text>
                                        }
                                        {item.buy_status == 3 &&
                                            <Text style={styles.itemTextRed}>{t('endOfLiquidation')}</Text>
                                        }
                                        {item.buy_status == 4 &&
                                            <Text style={styles.itemTextGreen}>{t('closingPositionEnded')}</Text>
                                        }
                                    </View>
                                </View> */}


                                <View style={styles.itemRowContainer}>
                                    <View>
                                        <Text style={{ color: '#fff' }}>{t('openPositionPrice')}</Text>
                                    </View>
                                    <View>
                                        {item.buy_price > 0 && <Text style={{ color: '#fff' }}>{item.buy_price}</Text>}
                                    </View>
                                </View>

                                <View style={styles.itemRowContainer}>
                                    <View>
                                        <Text style={{ color: '#fff' }}>{t('margin')}</Text>
                                    </View>
                                    <View>
                                        {item.buy_price > 0 && <Text style={{ color: '#fff' }}>{formatTwoDigits(item.bzj_money)}</Text>}
                                    </View>
                                </View>

                                <View style={styles.itemRowContainer}>
                                    <View>
                                        <Text style={{ color: '#fff' }}>{t('handfee')}</Text>
                                    </View>
                                    <View>
                                        {Number(item.yieid_rate) * 100 > 0 && <Text style={styles.itemTextWhite}>{formatNumberAuth((item.yieid_rate * 100 * item.bet_muli) * item.bzj_money * handfeeRate)}</Text>}
                                        {Number(item.yieid_rate) * 100 < 0 && <Text style={styles.itemTextWhite}>{formatNumberAuth((item.yieid_rate * 100 * item.bet_muli) * item.bzj_money * handfeeRate)}</Text>}
                                        {Number(item.yieid_rate) * 100 == 0 && <Text style={styles.itemTextWhite}>{formatNumberAuth((((item.sale_price1 - (item.buy_price * item.buy_number)) / (item.buy_price * item.buy_number)) * item.bet_muli) * item.bzj_money * handfeeRate)}</Text>}
                                    </View>
                                </View>

                                <View style={styles.itemRowContainer}>
                                    <View>
                                        <Text style={{ color: '#fff' }}>{t('openTime')}</Text>
                                    </View>
                                    <View>
                                        <Text style={{ color: '#fff' }}>
                                            {formatTimestampToDateTime(item.buy_time / 1000)}
                                        </Text>
                                    </View>
                                </View>
                                {/* <View style={styles.itemRowContainer}>

                                    <View>
                                        <TouchableOpacity>
                                            <Text style={{color:'#fff'}}>
                                                {formatTimestampToDateTime(item.buy_time / 1000)}
                                            </Text>
                                        </TouchableOpacity>
                                    </View>
                                    <View>
                                        {item.up_down == 0 &&
                                            <Text style={[styles.itemTextGreen, { marginRight: 5 }]}>{t('openLong')}</Text>
                                        }
                                        {item.up_down == 1 &&
                                            <Text style={[styles.itemTextRed, { marginRight: 5 }]}>{t('openShort')}</Text>
                                        }
                                    </View>
                                </View> */}
                                {/* <View style={styles.itemRowContainer}>
                                    <View>
                                        {item.bet_muli == 1 && <Text style={styles.itemTextGreen}>{t('quantity')}: {item.buy_number}</Text>}
                                        {item.bet_muli != 1 && <Text style={styles.itemTextGreen}>{t('quantity')}: {item.buy_number} - {item.bet_muli}X</Text>}
                                    </View>
                                    <View>

                                        {item.buy_price > 0 && <Text style={styles.itemTextRed}>{item.buy_price}</Text>}
                                    </View>
                                </View> */}

                                <View >
                                    <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                                        <View>
                                            {item.buy_status == 0 &&
                                                <TouchableOpacity style={{ backgroundColor: commonStyle.red, width: 100, padding: 3, borderRadius: 5 }} onPress={() => { cancelOrder(item.cd_id) }}>
                                                    <Text style={{ color: '#fff', textAlign: 'center', }}>{t('cancel')}</Text>
                                                </TouchableOpacity>
                                            }
                                        </View>
                                        {(item.buy_sale == 5 && item.buy_status == 1) &&
                                            <TouchableOpacity disabled={disabledButton[item.cd_id]} onPress={() => closePosition(item)} style={{ backgroundColor: commonStyle.green, width: 100, padding: 3, borderRadius: 5 }}>
                                                <Text style={{ color: '#fff', textAlign: 'center', }}>{t('closePosition')}</Text>
                                            </TouchableOpacity>
                                        }
                                    </View>
                                </View>
                            </View>
                        ))}
                    </View>
                </View>

                {/* <Modal
                    animationType="slide"
                    transparent={true}
                    visible={modalVisible}
                >
                    <View style={styles.modalContainer}>
                        <View style={styles.modalContent}>
                            <View style={{ justifyContent: 'center', alignItems: 'center' }}>
                                <AntDesign name="down" onPress={() => { closeModal() }}
                                    size={24} color="#707070" style={styles.modelDown}
                                />
                            </View>

                            <ScrollView
                                showsVerticalScrollIndicator={false}
                                showsHorizontalScrollIndicator={false}
                            >
                                <View>

                                    <TouchableOpacity onPress={() => handleEntrustType(0)}>
                                        <Text style={[styles.modalText]}>{t('limitOrder')}</Text>
                                    </TouchableOpacity>

                                    <TouchableOpacity onPress={() => handleEntrustType(1)} >
                                        <Text style={[styles.modalText]}>{t('marketOrder')}</Text>
                                    </TouchableOpacity>

                                </View>
                            </ScrollView>
                        </View>
                    </View>
                </Modal> */}


                <Modal
                    animationType="slide"
                    transparent={true}
                    visible={modalVisible2}
                >
                    <View style={styles.modalContainer}>
                        <View style={styles.modalContent}>
                            <View style={{ justifyContent: 'center', alignItems: 'center' }}>
                                <AntDesign name="down" onPress={() => { closeModal() }}
                                    size={24} color="#707070" style={styles.modelDown}
                                />
                            </View>

                            <ScrollView
                                showsVerticalScrollIndicator={false}
                                showsHorizontalScrollIndicator={false}
                            >
                                <View>

                                    {Array.from([5, 10, 15, 20, 25, 50, 75, 100], (item, index) => {
                                        return (
                                            <TouchableOpacity key={index} onPress={() => { handleLever(item) }}>
                                                <Text style={[styles.modalText]}>{item}x</Text>
                                            </TouchableOpacity>
                                        )
                                    })}

                                </View>
                            </ScrollView>
                        </View>
                    </View>
                </Modal>

                <Toast
                    ref={toastRef}
                    style={{ backgroundColor: 'gray' }}
                    position='top'
                    positionValue={200}
                    textStyle={{ color: '#fff' }}
                />
            </ScrollView >

            {/* <BottomButtons /> */}

        </View>
    );
}
);

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: commonStyle.bgColor,
        // alignItems: 'center'
        width: '100%',
        padding: 10
    },
    button: {
        paddingVertical: 8,
        paddingHorizontal: 2,
        borderRadius: 5,
        margin: 2
        // marginRight: 5
    },
    dropdownContainer: {
        alignItems: 'center',
        flexDirection: 'row',
        justifyContent: 'space-between',
        height: 40,
        marginTop: 10,
        color: commonStyle.green,
        backgroundColor: '#1B1D2A',
        padding: 5
    },
    dropdownText: {
        color: 'gray'
    },
    dropdownIcon: {
        color: 'gray',
        fontSize: 12
    },
    input: {
        marginTop: 5,
        color: commonStyle.green,
        backgroundColor: '#1B1D2A',
        padding: 5
    },
    itemContainer: {
        flexDirection: "column",
        paddingVertical: 10,
        paddingHorizontal: 20,
        borderBottomWidth: 1,
        backgroundColor: '#1B1D29',
        marginTop: 5,
    },
    itemRowContainer: {
        flexDirection: "row",
        justifyContent: "space-between",
        marginBottom: 4
    },
    itemTextGreen: {
        color: commonStyle.textGreenColor,
    },
    itemTextYellow: {
        color: commonStyle.yellow,
    },
    itemTextRed: {
        color: commonStyle.textRedColor,
    },
    itemTextGray: {
        color: commonStyle.textGrayColor
    },
    itemTextWhite: {
        color: commonStyle.textWhiteColor
    },
    modalContainer: {
        flex: 1,
        justifyContent: 'flex-end',
        alignItems: 'center',
        maxWidth: 500,
        width: '100%',
        marginHorizontal: 'auto'
        // backgroundColor: 'rgba(0,0,0,0.5)',
    },
    modalContent: {
        backgroundColor: '#1B1D29',
        // padding: 20,
        borderRadius: 10,
        width: '100%',
        height: '50%'
    },
    closeButton: {
        fontSize: 16,
        fontWeight: 'bold',
        color: '#fff',
        marginTop: 16,
        textAlign: 'center',
    },
    modelDown: {
        justifyContent: 'center',
        alignItems: 'center',
        marginHorizontal: 'auto',
        marginBottom: 15,
        marginTop: 20
    },
    modalText: {
        color: '#8C99B3',
        textAlign: 'center',
        height: 40,
        fontSize: 16,
        lineHeight: 35
    },
    modalTextSelected: {
        backgroundColor: '#0F1017',
        opacity: 0.45
    },
    bottomContainer: {
        position: 'absolute',
        bottom: 0,
        flexDirection: 'row',
        justifyContent: 'space-between',
        width: '100%',
        backgroundColor: commonStyle.bgColor,
    },
    bottomText: {
        textAlign: 'center',
        color: commonStyle.textWhiteColor,
    },
    buttonLeft: {
        backgroundColor: commonStyle.green,
        paddingVertical: 10,
        // width: '80%',
        justifyContent: "center",
        // paddingHorizontal: 60,
        borderRadius: 5,
        padding: 40
    },
    buttonRight: {
        backgroundColor: '#F5465C',
        // width: '80%',
        paddingVertical: 10,
        // paddingHorizontal: 60,
        borderRadius: 5,
        padding: 40
    },
    tabActive: {
        borderBottomColor: commonStyle.white
    },
    entrustTextActive: {
        color: commonStyle.white
    }
});

export default App;
