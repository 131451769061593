import React, { useState, useEffect } from 'react';
import { StyleSheet, View, Text, TextInput, TouchableOpacity, Image, FlatList, ScrollView } from 'react-native';
import { useNavigation, useFocusEffect } from '@react-navigation/native';
import { useTranslation } from 'react-i18next';
import { commonStyle } from '../components/style';
import { Feather, MaterialIcons } from '@expo/vector-icons';
import { getUserbalance, getWebsiteInfo } from '../api/apis';
import { unitconversion, navigateRequireLogin } from '../components/util'
import AsyncStorage from '@react-native-community/async-storage';
import SpliteLine from '../components/spliteLine'

const Wallet = () => {
    const navigation = useNavigation();
    const { i18n, t } = useTranslation();

    const [websiteInfoData, setWebsiteInfoData] = useState(false);

    const [coinList, setCoinList] = useState([
        { name: 'BTC', num: 0 },
        { name: 'ETH', num: 0 },

        { name: 'BCH', num: 0 },
        { name: 'UNI', num: 0 },
        { name: '1INCH', num: 0 },
        { name: 'LTC', num: 0 },
        { name: 'XRP', num: 0 },
        { name: 'ETC', num: 0 },
        { name: 'DOGE', num: 0 },
        { name: 'FIL', num: 0 },
        { name: 'UMA', num: 0 },
        { name: 'AAVE', num: 0 },
        { name: 'BAL', num: 0 },
        { name: 'CHZ', num: 0 },
        { name: 'HT', num: 0 },
        { name: 'EOS', num: 0 },
        { name: 'ADA', num: 0 },
        { name: 'DOT', num: 0 },
        { name: 'XMR', num: 0 },
        { name: 'BSV', num: 0 },
        { name: 'DASH', num: 0 },

        { name: 'TRX', num: 0 },
        { name: 'BNB', num: 0 },
        { name: 'USDC', num: 0 },

        // { name: 'ALPACA', num: 0 },
        // { name: 'BURGER', num: 0 },
        // { name: 'LAZIO', num: 0 },
        // { name: 'VIDT', num: 0 },
        // { name: 'FIO', num: 0 },
        // { name: 'HARD', num: 0 },
        // { name: 'VITE', num: 0 },
        // { name: 'WING', num: 0 },
        // { name: 'AMB', num: 0 },
        // { name: 'VGX', num: 0 },
        // { name: 'JUV', num: 0 },
        // { name: 'EPX', num: 0 },
        // { name: 'ACM', num: 0 },
        // { name: 'ATM', num: 0 },
        // { name: 'CVP', num: 0 },
        // { name: 'PROS', num: 0 },
        // { name: 'BIDR', num: 0 },
        // { name: 'IDRT', num: 0 },
        // { name: 'OOKI', num: 0 },
        // { name: 'ASR', num: 0 },

    ]);

    const [eye, setEye] = useState('on');
    // const [searchText, setSearchText] = useState('');
    // const [filteredData, setFilteredData] = useState(data);
    const [balance, setBalance] = useState('');
    const [currency, setCurrency] = useState('');

    useEffect(() => {
        init()
    }, []);

    useFocusEffect(
        React.useCallback(() => {
            userbalance()
        }, [])
    );

    const handleEye = (index) => {
        console.log(index);
        if (index === 'on') {
            setEye('off');
        } else {
            setEye('on');
        }
    };

    // const handleSearch = (text) => {
    //     console.log(text)
    //     setSearchText(text);

    //     // 过滤数据
    //     const filtered = data.filter((item) =>
    //         item.name.toLowerCase().includes(text.toLowerCase())
    //     );
    //     setFilteredData(filtered);
    // };


    const init = async () => {
        const response = await getWebsiteInfo();
        if (response.status == '1') {
            setWebsiteInfoData(response.Data)
        }
    }

    // 获取余额
    const userbalance = async () => {

        const userinfo = await AsyncStorage.getItem('userinfo');
        if (!userinfo) {
            navigation.navigate('Login');
        }


        getUserbalance().then(response => {
            if (response.status == 1 && response.Data.Code == '1') {
                setBalance(response.Data.BackData);
            }
        }).catch(error => {
            // console.error(error);
        });

        const l = await AsyncStorage.getItem('websiteInfo');
        const lj = JSON.parse(l);
        if (lj.Data.curr != '') {
            setCurrency(lj.Data.curr)
        }


        getUserbalance({ type: "all" }).then(res => {
            const coin = res.Data.other;
            const upateCoinList = coinList.map((data) => {
                const coinName = data.name;
                const coinNum = coin[coinName] / 100 || 0;
                return {
                    ...data,
                    num: parseFloat(coinNum),
                };
            });
            setCoinList(upateCoinList);
        })


    };

    const goToRechargeRecordScreen = () => {
        navigation.navigate('RechargeRecord');
    };

    const goToWithdrawalRecordScreen = () => {
        navigation.navigate('WithdrawalRecords');
    };

    const goToContractRecordsScreen = () => {
        navigation.navigate('BillingRecord');
    }

    const goToBettingScreen = () => {
        // navigation.navigate('ContractRecords');
        navigation.navigate('BetRecord');
    }

    const goToExchangeScreen = (amount, currency) => {
        const route = {
            name: 'Exchange', // 路由地址
            params: { amount, currency: currency },
        };
        navigateRequireLogin(navigation, route);
    }

    return (
        <View style={styles.container}>
            <ScrollView showsVerticalScrollIndicator={false} showsHorizontalScrollIndicator={false} style={{ width: '100%' }}>
                <View style={styles.contentContainer}>
                    <View style={styles.accountInfoContainer}>
                        <View style={styles.accountInfoHeader}>
                            <Text style={styles.totalAssetsText}>{t('totalAssets')}</Text>
                            {/* <View style={styles.eyeIconContainer}>
                            {eye === 'on' && (
                                <TouchableOpacity onPress={() => handleEye('on')}>
                                    <Feather name="eye" size={20} color="#707070" />
                                </TouchableOpacity>
                            )}
                            {eye === 'off' && (
                                <TouchableOpacity onPress={() => handleEye('off')}>
                                    <Feather name="eye-off" size={20} color="#707070" />
                                </TouchableOpacity>
                            )}
                        </View> */}
                        </View>
                        <View>
                            <Text style={styles.usdtAmountText}>
                                {eye === 'on' ? unitconversion(balance) : '***** '}
                            </Text>
                        </View>
                        <View>
                            <Text style={{ color: '#13B777' }}>{currency}</Text>
                            {/* <Text style={styles.cnyAmountText}>
                            = {eye === 'on' ? '9.999999 CNY' : '***** CNY'}
                        </Text> */}
                        </View>
                    </View>

                    <View style={styles.section}>
                        {/* <View style={styles.iconText}>
                        <TouchableOpacity style={styles.icon} onPress={goToBettingScreen}>
                            <Image style={styles.image} source={require('../../assets/imgs/wallet/bill.png')} />
                        </TouchableOpacity>
                        <Text style={styles.labelTop}>{t('betting')}</Text>
                    </View> */}

                        <View style={styles.iconText}>
                            <TouchableOpacity style={styles.icon} onPress={goToContractRecordsScreen}>
                                <Image style={styles.image} source={require('../../assets/imgs/wallet/bill.png')} />
                            </TouchableOpacity>
                            <Text style={styles.labelTop}>{t('bill')}</Text>
                        </View>

                        <View style={styles.iconText}>
                            <TouchableOpacity style={styles.icon} onPress={() => goToRechargeRecordScreen()}>
                                <Image style={styles.image} source={require('../../assets/imgs/wallet/deposit.png')} />
                            </TouchableOpacity>
                            <Text style={styles.labelTop}>{t('recharge')}</Text>
                        </View>
                        <View style={styles.iconText}>
                            <TouchableOpacity style={styles.icon} onPress={() => goToWithdrawalRecordScreen()}>
                                <Image style={styles.image} source={require('../../assets/imgs/wallet/extractCurrency.png')} />
                            </TouchableOpacity>
                            <Text style={styles.labelTop}>{t('withdrawal')}</Text>
                        </View>
                    </View>

                    {/* <View style={{ flexDirection: 'row', alignItems: 'center', backgroundColor: '#2C3445', width: 150, marginBottom: 10 }}>
                    <MaterialIcons name="search" size={20} color="#fff" />
                    <TextInput
                        style={styles.input}
                        placeholder={t('searchCurrency')}
                        onChangeText={handleSearch}
                        underlineColorAndroid="transparent"
                        placeholderTextColor="#707070"
                        maxLength={10}
                        value={searchText}
                    />
                </View> */}

                    <View style={styles.separator}></View>

                    <View style={{ backgroundColor: '#1B1D29', height: '100%', paddingBottom: 10, borderRadius: 5 }}>
                        <View style={{ flexDirection: 'column' }}>
                            <View style={{ flexDirection: 'row', justifyContent: 'space-between', marginVertical: 15, marginHorizontal: 10 }}>
                                <View style={{ flexDirection: 'row' }}>
                                    <Image style={styles.image} source={require('../../assets/imgs/currency/USDT.png')} />
                                    <Text style={styles.walletItemTitle}>USDT</Text>
                                </View>
                                <View >
                                    <Text style={styles.walletItemTitle}>{unitconversion(balance)}</Text>
                                </View>
                            </View>
                            <SpliteLine />
                            {coinList.map((item, index) =>
                            // (websiteInfoData && (websiteInfoData.hasOwnProperty(`COIN_${item.name}`) && websiteInfoData[`COIN_${item.name}`] == 1) || !websiteInfoData.hasOwnProperty(`COIN_${item.name}`)) &&
                            (
                                <TouchableOpacity key={index} onPress={() => goToExchangeScreen(0, item.name)}>
                                    <View style={{ flexDirection: 'row', justifyContent: 'space-between', marginVertical: 15, marginHorizontal: 10 }}>
                                        <View style={{ flexDirection: 'row' }}>
                                            {item.name == 'BTC' &&
                                                <Image style={styles.image} source={require('../../assets/imgs/currency/BTC.png')} />
                                            }
                                            {item.name == 'ETH' &&
                                                <Image style={styles.image} source={require('../../assets/imgs/currency/ETH.png')} />
                                            }
                                            {item.name == 'TRX' &&
                                                <Image style={styles.image} source={require('../../assets/imgs/currency/TRX.png')} />
                                            }
                                            {item.name == 'BNB' &&
                                                <Image style={styles.image} source={require('../../assets/imgs/currency/BNB.png')} />
                                            }
                                            {item.name == 'USDC' &&
                                                <Image style={styles.image} source={require('../../assets/imgs/currency/USDC.png')} />
                                            }

                                            {item.name == 'BCH' &&
                                                <Image style={styles.image} source={require('../../assets/imgs/currency/BCH.png')} />
                                            }
                                            {item.name == 'UNI' &&
                                                <Image style={styles.image} source={require('../../assets/imgs/currency/UNI.png')} />
                                            }
                                            {item.name == '1INCH' &&
                                                <Image style={styles.image} source={require('../../assets/imgs/currency/1INCH.png')} />
                                            }
                                            {item.name == 'LTC' &&
                                                <Image style={styles.image} source={require('../../assets/imgs/currency/LTC.png')} />
                                            }
                                            {item.name == 'XRP' &&
                                                <Image style={styles.image} source={require('../../assets/imgs/currency/XRP.png')} />
                                            }
                                            {item.name == 'ETC' &&
                                                <Image style={styles.image} source={require('../../assets/imgs/currency/ETC.png')} />
                                            }
                                            {item.name == 'DOGE' &&
                                                <Image style={styles.image} source={require('../../assets/imgs/currency/DOGE.png')} />
                                            }
                                            {item.name == 'FIL' &&
                                                <Image style={styles.image} source={require('../../assets/imgs/currency/FIL.png')} />
                                            }
                                            {item.name == 'UMA' &&
                                                <Image style={styles.image} source={require('../../assets/imgs/currency/UMA.png')} />
                                            }
                                            {item.name == 'AAVE' &&
                                                <Image style={styles.image} source={require('../../assets/imgs/currency/AAVE.png')} />
                                            }
                                            {item.name == 'BAL' &&
                                                <Image style={styles.image} source={require('../../assets/imgs/currency/BAL.png')} />
                                            }
                                            {item.name == 'CHZ' &&
                                                <Image style={styles.image} source={require('../../assets/imgs/currency/CHZ.png')} />
                                            }
                                            {item.name == 'HT' &&
                                                <Image style={styles.image} source={require('../../assets/imgs/currency/HT.png')} />
                                            }
                                            {item.name == 'EOS' &&
                                                <Image style={styles.image} source={require('../../assets/imgs/currency/EOS.png')} />
                                            }
                                            {item.name == 'ADA' &&
                                                <Image style={styles.image} source={require('../../assets/imgs/currency/ADA.png')} />
                                            }
                                            {item.name == 'DOT' &&
                                                <Image style={styles.image} source={require('../../assets/imgs/currency/Polkadot.png')} />
                                            }
                                            {item.name == 'XMR' &&
                                                <Image style={styles.image} source={require('../../assets/imgs/currency/XMR.png')} />
                                            }
                                            {item.name == 'BSV' &&
                                                <Image style={styles.image} source={require('../../assets/imgs/currency/BSV.png')} />
                                            }
                                            {item.name == 'DASH' &&
                                                <Image style={styles.image} source={require('../../assets/imgs/currency/DASH.png')} />
                                            }

                                            {item.name == 'ALPACA' &&
                                                <Image style={styles.image} source={require('../../assets/imgs/currency/ALPACA.png')} />
                                            }
                                            {item.name == 'BURGER' &&
                                                <Image style={styles.image} source={require('../../assets/imgs/currency/BURGER.png')} />
                                            }
                                            {item.name == 'LAZIO' &&
                                                <Image style={styles.image} source={require('../../assets/imgs/currency/LAZIO.png')} />
                                            }
                                            {item.name == 'VIDT' &&
                                                <Image style={styles.image} source={require('../../assets/imgs/currency/VIDT.png')} />
                                            }
                                            {item.name == 'FIO' &&
                                                <Image style={styles.image} source={require('../../assets/imgs/currency/FIO.png')} />
                                            }
                                            {item.name == 'HARD' &&
                                                <Image style={styles.image} source={require('../../assets/imgs/currency/HARD.png')} />
                                            }
                                            {item.name == 'VITE' &&
                                                <Image style={styles.image} source={require('../../assets/imgs/currency/VITE.png')} />
                                            }
                                            {item.name == 'WING' &&
                                                <Image style={styles.image} source={require('../../assets/imgs/currency/WING.png')} />
                                            }
                                            {item.name == 'AMB' &&
                                                <Image style={styles.image} source={require('../../assets/imgs/currency/AMB.png')} />
                                            }
                                            {item.name == 'VGX' &&
                                                <Image style={styles.image} source={require('../../assets/imgs/currency/VGX.png')} />
                                            }
                                            {item.name == 'JUV' &&
                                                <Image style={styles.image} source={require('../../assets/imgs/currency/JUV.png')} />
                                            }
                                            {item.name == 'EPX' &&
                                                <Image style={styles.image} source={require('../../assets/imgs/currency/EPX.png')} />
                                            }
                                            {item.name == 'ACM' &&
                                                <Image style={styles.image} source={require('../../assets/imgs/currency/ACM.png')} />
                                            }
                                            {item.name == 'ATM' &&
                                                <Image style={styles.image} source={require('../../assets/imgs/currency/ATM.png')} />
                                            }
                                            {item.name == 'CVP' &&
                                                <Image style={styles.image} source={require('../../assets/imgs/currency/CVP.png')} />
                                            }
                                            {item.name == 'PROS' &&
                                                <Image style={styles.image} source={require('../../assets/imgs/currency/PROS.png')} />
                                            }
                                            {item.name == 'BIDR' &&
                                                <Image style={styles.image} source={require('../../assets/imgs/currency/BIDR.png')} />
                                            }
                                            {item.name == 'IDRT' &&
                                                <Image style={styles.image} source={require('../../assets/imgs/currency/IDRT.png')} />
                                            }
                                            {item.name == 'OOKI' &&
                                                <Image style={styles.image} source={require('../../assets/imgs/currency/OOKI.png')} />
                                            }
                                            {item.name == 'ASR' &&
                                                <Image style={styles.image} source={require('../../assets/imgs/currency/ASR.png')} />
                                            }
                                            <Text style={styles.walletItemTitle}>{item.name}</Text>
                                        </View>
                                        <View >
                                            <Text style={styles.walletItemTitle}>{item.num}</Text>
                                        </View>
                                    </View>
                                    <SpliteLine />
                                </TouchableOpacity>
                            ))}
                        </View>
                    </View>



                </View>
            </ScrollView>
        </View>
    );
};

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: commonStyle.bgColor,
        alignItems: 'center',
    },
    contentContainer: {
        // width: '95%',
        marginTop: 15,
        marginHorizontal: 10
    },
    accountInfoContainer: {
        backgroundColor: '#2C3445',
        borderRadius: 5,
        flexDirection: 'column',
        padding: 10,
    },
    accountInfoHeader: {
        flexDirection: 'row',
    },
    totalAssetsText: {
        color: '#fff',
    },
    eyeIconContainer: {
        marginLeft: 5,
    },
    usdtAmountText: {
        color: '#13B777',
        fontSize: 25,
    },
    cnyAmountText: {
        color: '#13B777',
    },
    section: {
        marginVertical: 20,
        flexDirection: 'row',
        justifyContent: 'space-around',
    },
    iconText: {
        flexDirection: 'colum',
        alignItems: 'center',
        color: '#828B9F',
    },
    icon: {},
    labelTop: {
        color: commonStyle.textWhiteColor,
        marginTop: 2,
    },
    image: {
        width: 25,
        height: 25,
        // marginVerical: 5,
        marginHorizontal: 5,
        borderRadius: 90
    },
    separator: {
        height: 1,
        backgroundColor: '#fff',
    },
    input: {
        borderWidth: 0,
        borderBottomColor: 'transparent',
        color: '#fff',
        borderRadius: 2,
        padding: 5,
        width: 130,
    },
    walletItemTitle: {
        fontSize: 18,
        color: '#fff',
        marginTop: 0,
        marginRight: 5
    },
    walletItemText: {
        color: '#fff',
        fontSize: 16,
    }
});

export default Wallet;