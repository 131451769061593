import React from 'react';
import { StyleSheet, View, Text, ScrollView, Platform } from 'react-native';
import { WebView } from 'react-native-webview';
import { useTranslation } from 'react-i18next';
import { useNavigation } from '@react-navigation/native';
import { commonStyle } from '../components/style';

const ArticleDetail = (props) => {
  const { i18n, t } = useTranslation();
  const title_id = props.route.params.articleId
  let lang = i18n.language.toUpperCase()
  if (lang != 'CN' && lang != 'EN') {
    lang = 'EN'
  }

  const navigation = useNavigation();

  let title = ''
  if (title_id == 1) {
    title = t('aboutUs');
  }
  if (title_id == 2) {
    title = t('userTerms');
  }
  if (title_id == 3) {
    title = t('privacyPolicy');
  }

  React.useLayoutEffect(() => {
    navigation.setOptions({
      title: title,
      headerStyle: {
        backgroundColor: commonStyle.black,
        borderBottomWidth: 0
      },
      headerTitleStyle: {
      },
    });
  }, [navigation]);


  const articleContent = {
    CN: {
      1: `
      Axion

      <br>Axion is a leading global digital asset exchange, recognized for its innovation, security, and exceptional trading experience. Specializing in multi-asset trading, Axion provides access to over 250 digital assets.

      <br>Founded in 2019, Axion has built a reputation for reliability and cutting-edge technology. The platform offers spot trading, margin trading, and a suite of advanced derivatives and staking products tailored for both individual and institutional investors. Axion’s commitment to transparency and security has earned the trust of over 12 million clients worldwide.

      <br>Axion also provides 24/7 multilingual customer support and industry-leading educational resources to empower traders of all experience levels.

      <br>For more information about Axion, please visit https://axioncoins.com/

      <br>Daily Market Report

      <br>The Axion Daily Market Report is published at approximately 3:00 pm UTC. Prices reflect the volume-weighted average price (VWAP) across all trading pairs for each listed asset. Daily volume is calculated as the total trading volume of each asset over the last 24 hours. Price and volume data are captured at 2:00 pm UTC.

      <br>Assets are displayed in the report by their daily trading volume, ranked from highest to lowest. To maintain clarity, assets with minimal trading activity may not be included. Any news articles or insights shared in the report are intended solely for informational purposes and do not represent Axion’s endorsement or official stance.

      <br>Disclaimer

      <br>Axion is not an investment advisor and does not provide recommendations on the suitability of specific assets, strategies, or financial actions. Any investment decisions you make are entirely your responsibility. Please carefully evaluate your financial situation and goals, and seek professional financial advice before making any investment decisions.
       `,
      2: `
Thank you for choosing Axion products.
<br>Axion is a platform specifically designed for users to provide blockchain related services (hereinafter referred to as "the Service" or "the Service"). For the convenience of expression in this agreement, the company and the website are collectively referred to as "we" or other first person names in this agreement. As long as the natural persons or other entities logging into the website are users of this website, for the convenience of this agreement, they are collectively referred to as "you" or other second person. For the convenience of expression in this agreement, we and you are collectively referred to as the "parties" in this agreement, and we or you are individually referred to as the "party". All content on this website may be available in multiple language versions for the convenience of users. In case of any conflicts or omissions, the Chinese content shall prevail.
<br>Important Notice:
<br>We would like to remind you that:
<br>1. The digital assets themselves are not issued by any financial institution, company or this website;
<br>2. The digital asset market is brand new, unconfirmed, and may not grow;
<br>3. Digital asset investment carries extremely high risks, and non professional teams or institutions are advised to invest with caution;
<br>4. It is prohibited to use this website to engage in all illegal trading activities such as money laundering, smuggling, and commercial bribery. If such incidents are discovered, this website will take various available measures, including but not limited to freezing accounts, notifying relevant authorities, etc. We will not bear any responsibility or related expenses arising therefrom, and reserve the right to pursue responsibility from relevant individuals.
<br>5. It is prohibited to use this website for any unethical trading activities such as malicious market manipulation and unfair trading. If such incidents are discovered, this website will take preventive protective measures such as warning, restricting trading, and shutting down accounts for all unethical behaviors such as malicious price manipulation and malicious influence on the trading system. We do not assume any responsibility or related expenses arising from this, and reserve the right to pursue responsibility from relevant parties.
<br>6. The amount and profit of financial mining can only be withdrawn after the transaction.
<br>When you withdraw money, the website will charge a commission of 10%. Users below VIP5 on this website can only withdraw once a month, while users above VIP5 can withdraw three times a month.
<br>8. Each user's USDT deposit needs to be fully traded once, and withdrawals can only be made after the transaction volume is completed.
<br>9. The credit score of the account is 100 points. If the user score is below 100 points, it is considered a bad account and withdrawal will fail. If the credit score is insufficient, it can be recharged, that is, 1000 USDT=1% points.
<br>1、 General Provisions
<br>1.1 The User Agreement (hereinafter referred to as "this Agreement" or "these Terms and Conditions") consists of the main text, the Privacy Policy, the User Anti Money Laundering Regulations, and various rules, statements, explanations, etc. that have been published or may be published on this website in the future.
<br>1.2 Before using the services provided by this website, you should carefully read this agreement. If there are any misunderstandings or other necessary matters, please consult a professional lawyer. If you do not agree to this agreement or have any questions about it, please immediately stop using the services provided by this website or no longer log in to this website. Once you log in to this website, use any services on this website, or engage in any other similar behavior, it means that you have understood and fully agreed to the contents of this agreement, including any modifications made by this website to this agreement at any time.
<br>1.3 You can become a user of this website by filling in relevant information according to the requirements of this website and successfully registering through other relevant procedures. Clicking the "Agree" button during the registration process means that you have reached an agreement with the company in the form of an electronic signature; When you click any button marked with "agree" or similar meaning during the use of this website, or when you actually use the services provided by this website in any other way allowed by this website, it means that you fully understand, agree, and accept all the terms and conditions under this agreement. Without your handwritten written signature, this agreement has no legal binding effect on you.
<br>1.4 After becoming a user of this website, you will receive a user account and corresponding password, which will be kept by the user; Users shall be legally responsible for all activities and events conducted under their account.
<br>Only registered users of this website can use the digital asset trading platform provided by this website for trading and enjoy other services that are only available to users as stipulated by this website.
<br>1.6 By registering and using any services and features provided by this website, you will be deemed to have read, understood, and accepted all terms and conditions of this agreement:
<br>1.6.1 You confirm that you are over 18 years old or have the legal age to enter into contracts according to different applicable laws. Your registration, sale, purchase, and publication of information on this website to receive services from this website shall comply with the relevant laws and regulations of sovereign countries or regions that have jurisdiction over you, and have sufficient ability to accept these terms, enter into transactions, and use this website for digital asset transactions.
<br>1.6.2 You guarantee that all digital assets belonging to you involved in the transaction are legally acquired and owned.
<br>1.6.3 You agree to assume full responsibility and any gains or losses for your own trading or non trading activities.
<br>1.6.4 You confirm that the information provided during registration is true and accurate.
<br>1.6.5 You agree to comply with any relevant legal provisions, including paying 20% personal income tax and reporting any transaction profits for tax purposes.
<br>1.6.6 You comply with all applicable legal requirements, including but not limited to all tax laws and regulations, foreign exchange control requirements, and registration requirements.
<br>1.6.7 You agree not to engage in or participate in any behavior or activity that harms the interests of this website or the company at any time, regardless of whether it is related to the services provided by this website.
<br>1.6.8 This agreement only binds the rights and obligations between you and us, and does not involve legal relationships or disputes arising from digital asset transactions between users of this website and other websites and you.
<br>2、 We reserve the right to revise this agreement at any time and publish it on our website without separately notifying you. The revised agreement will be marked with the change time on the homepage of this agreement, and once published on the website, it will automatically take effect immediately. You should browse and pay attention to the update time and content of this agreement from time to time. If you do not agree with the relevant changes, you should immediately stop using the services of this website; By continuing to use the services of this website, you accept and agree to be bound by the revised agreement.
<br>3、 Register
<br>3.1 Registration
<br>You confirm and promise that when you complete the registration process or use the services provided by this website in other ways permitted by this website, you shall be a natural person with the ability to sign this agreement and use the services provided by this website in accordance with applicable laws and regulations. Once you click the agree to register button, it means that you have agreed to the content of the agreement. If you do not possess the aforementioned subject qualifications, you shall bear all consequences arising therefrom, and the website reserves the right to cancel or permanently freeze your account and hold you responsible. At the same time, by accessing and using the services of this website, you represent and warrant that you are not listed on the FATF (Financial Action Task Force on Money Laundering) list, the OFAC (Office of Foreign Assets Control) designated "Specially Designated Nationals" list, or any trade or economic sanctions list (such as the United Nations Security Council sanctions list). We reserve the right to choose markets and jurisdictions to operate our business, and may, at our discretion, restrict or refuse to provide this service in certain countries.
<br>3.2 Registration Purpose
<br>You confirm and promise that your registration on this website is not for the purpose of violating laws and regulations or disrupting the order of digital asset trading on this website.
<br>3.3.1 You agree to provide valid email address, mobile phone number, and other information as required on the user registration page of this website. You may use the email address, mobile phone number you provide or confirm, or other methods allowed by this website as a login method to enter this website. If necessary, in accordance with the relevant laws and regulations of different jurisdictions, you must provide your real name, identity documents, and other relevant information as required by laws and regulations, privacy policies, and anti money laundering provisions, and continuously update your registration information to meet the requirements of timeliness, detail, and accuracy. All original typed information will be referenced as registration information. You shall be responsible for the authenticity, completeness, and accuracy of such information, and shall bear any direct or indirect losses and adverse consequences arising therefrom.
<br>3.3.2 If the laws, regulations, rules, orders, and other norms of your sovereign country or region require real name registration for mobile phone numbers, you agree that the registered mobile phone number provided has been registered with real name. If you do not provide it in accordance with the regulations, any direct or indirect losses and adverse consequences caused to you shall be borne by you.
<br>4、 Service
<br>This website only provides you with online platform services and does not participate in the buying and selling of digital assets as a buyer or seller. This website does not currently provide any services related to the deposit and withdrawal of national legal currency.
<br>4.1 Service Content
<br>4.1.1 You have the right to browse real-time market trends and trading information of various digital asset products on this website, and have the right to submit digital asset trading instructions and complete digital asset transactions through this website.
<br>4.1.2 You have the right to view the information under your user account on this website and to use the functions provided by this website for operation.
<br>4.1.3 You have the right to participate in website activities organized by this website in accordance with the activity rules published on this website.
<br>4.1.4 This website promises to provide you with other services.
<br>5、 Rights and obligations of this website
<br>5.1 If you do not have the registration qualifications stipulated in this agreement, this website has the right to refuse your registration. For those who have already registered, this website has the right to cancel your account, and reserves the right to pursue responsibility from you or your authorized agent. Meanwhile, this website reserves the right to decide whether to accept your registration under any other circumstances.
<br>5.2 If this website discovers that you or your associated account users are not suitable for high-risk investment based on our own judgment, we have the right to suspend or terminate the use of your account and all associated accounts.
<br>5.3 If this website reasonably suspects that the information you provide is incorrect, untrue, invalid or incomplete through technical testing, manual sampling and other testing methods, it has the right to notify you to correct, update the information or suspend or terminate the provision of services to this website.
<br>5.4 This website has the right to correct any information displayed on this website if it is found to have obvious errors.
<br>5.5 This website reserves the right to modify, suspend or terminate its services at any time, and the exercise of this website's right to modify or suspend services does not require prior notice to you; If one or more services of this website are terminated, the termination shall take effect from the date of publication of the termination notice on this website.
<br>5.6 This website shall adopt necessary technical means and management measures to ensure the normal operation of this website, and provide necessary and reliable trading environment and trading services to maintain the order of digital asset trading.
<br>5.7 This website ensures the security of your digital assets through measures such as strengthening technological investment and enhancing security measures. It is our obligation to notify you in advance when there are foreseeable security risks to your account.
<br>5.8 This website reserves the right to charge a partial handling fee when you conduct relevant transactions or withdraw funds.
<br>5.9 This website has the right to delete any content or information that does not comply with laws, regulations, or the rules of this website at any time. This website does not need to notify you in advance to exercise such rights.
<br>5.10 This website has the right to request more information or materials from you in accordance with the laws, regulations, rules, orders, and other norms of your sovereign country or region, and take reasonable measures to comply with local norms. You have the obligation to cooperate; This website has the right to suspend or permanently suspend the opening of this website and some or all of its services to you in accordance with the requirements of laws, regulations, rules, orders, and other norms of your sovereign country or region.
<br>5.11 If the withdrawal amount of the first three withdrawal instructions initiated by the user after completing the payment of taxes or channel fees exceeds the monetary policy red line and the maximum transfer amount (1000 USDT) stipulated by the monetary regulatory agency, the account will be automatically activated by the personal asset verification program (capital verification program) of the US Securities and Exchange Commission (SEC) and the US Internal Revenue Service (IRS). To prevent cheating during the verification process, it is prohibited to use Coinbase account funds to complete the verification procedures
            `,
      3: `

      <br>axioncoins.com

      <br>Axion Privacy Policy

      <br>1. Introduction
      <br>Welcome to Axion! We value your privacy and are committed to protecting your personal data. This Privacy Policy outlines how Axion (hereinafter referred to as "we," "us," or "our") collects, uses, and safeguards your information when you access our website (https://axioncoins.com/), register for our services, or use any of the features provided on our platform.

      <br>By using Axion's platform, you acknowledge that you have read, understood, and agree to the terms of this Privacy Policy.

      <br>2. Parties
      <br>2.1 Axion operates under the jurisdiction of [Your Company's Jurisdiction] and specializes in digital asset trading and related services.

      <br>2.2 Users: Any natural person or entity that logs into Axion's website or uses its services is considered a user of the platform (referred to as "you" or "your").

      <br>2.3 Collectively, "you" and "we" are referred to as the "Parties."

      <br>3. Scope of Privacy Policy
      This Privacy Policy describes the types of information we may collect from you, how we use and protect that information, and your rights regarding your personal data.

      <br>4. Information We Collect
      <br>4.1 Information You Provide:
      <br>We collect personal information when you sign up, authenticate, or interact with our services, including:

      <br>Email address and phone number (required for registration).
      <br>Full name, date of birth, and government-issued identification (required for authentication).
      <br>Wallet address and balance (required for wallet integration).
      <br>4.2 Automatically Collected Information:
      <br>When you use our platform, we may automatically collect technical information, such as:

      <br>Device information, including IP address and browser type.
      <br>Activity logs and browsing patterns.
      <br>Cookies to track your preferences and enhance your experience.
      <br>4.3 Purpose-Specific Collections:

      <br>Registration: Name, email, phone number, and referral codes (optional).
      <br>Transactions: Wallet addresses, transaction histories, and balances.
      <br>Support Services: Contact information to resolve inquiries.
      <br>5. How We Use Your Information
      <br>5.1 Axion collects and uses your personal information for the following purposes:

      <br>To provide you with our services and ensure platform security.
      <br>To verify your identity and comply with regulatory requirements.
      <br>To enhance user experience through analytics and feedback.
      <br>To send notifications, updates, and promotional offers (only if you have opted in).
      <br>To prevent fraud, monitor suspicious activity, and comply with legal obligations.
      <br>5.2 Axion will not sell or trade your personal information without your explicit consent. However, we may share it with trusted partners or affiliates to provide you with enhanced services.

      <br>6. Cookies
      <br>We use cookies to enhance your experience on our platform. These cookies help us:

      <br>Track usage patterns.
      <br>Store user preferences.
      <br>Deliver personalized content.
      <br>You can disable cookies through your browser settings, but doing so may limit certain features of our platform.

      <br>7. Data Security
      <br>Axion implements strict measures to ensure the security of your personal information, including:

      <br>Data encryption and secure servers.
      <br>Limited access to sensitive data for authorized personnel only.
      <br>Continuous monitoring to prevent unauthorized access.
      <br>While we strive to protect your data, no method of transmission over the internet is completely secure, and we cannot guarantee absolute security.

      <br>8. International Data Transfers
      <br>Your data may be transferred to and processed in other jurisdictions where Axion operates. We ensure that these transfers comply with applicable laws and maintain the confidentiality of your data.

      <br>9. Data Retention and Destruction
      <br>We retain personal data only as long as necessary for the purposes outlined in this policy or as required by law. Once the retention period expires, data is securely deleted or anonymized.

      <br>10. Minors
      <br>Axion does not offer services to individuals under the age of 18. If a minor's information is inadvertently collected, we will delete it upon request.

      <br>11. Your Rights
      <br>You have the right to:
      <br>Access, correct, or delete your personal data.
      <br>Withdraw consent for data processing.
      <br>Request restrictions on data usage.
      <br>To exercise these rights, please contact us at axcoinsoffical@gmail.com

      <br>12. Policy Updates
      <br>We reserve the right to update this Privacy Policy. Changes will be communicated through our website, and your continued use of the platform signifies acceptance of the updated policy.

      <br>13. Contact Us
      <br>If you have any questions about this Privacy Policy or your personal data, please contact our Data Protection Officer:

      <br>Email: axcoinsoffical@gmail.com
      <br>Thank you for trusting Axion. Your privacy is our priority.

      <br>This policy is tailored to Axion while ensuring clarity and compliance with common privacy
      `
    },
    EN: {
      1: `
      Axion

      <br>Axion is a leading global digital asset exchange, recognized for its innovation, security, and exceptional trading experience. Specializing in multi-asset trading, Axion provides access to over 250 digital assets.

      <br>Founded in 2019, Axion has built a reputation for reliability and cutting-edge technology. The platform offers spot trading, margin trading, and a suite of advanced derivatives and staking products tailored for both individual and institutional investors. Axion’s commitment to transparency and security has earned the trust of over 12 million clients worldwide.

      <br>Axion also provides 24/7 multilingual customer support and industry-leading educational resources to empower traders of all experience levels.

      <br>For more information about Axion, please visit https://axioncoins.com/

      <br>Daily Market Report

      <br>The Axion Daily Market Report is published at approximately 3:00 pm UTC. Prices reflect the volume-weighted average price (VWAP) across all trading pairs for each listed asset. Daily volume is calculated as the total trading volume of each asset over the last 24 hours. Price and volume data are captured at 2:00 pm UTC.

      <br>Assets are displayed in the report by their daily trading volume, ranked from highest to lowest. To maintain clarity, assets with minimal trading activity may not be included. Any news articles or insights shared in the report are intended solely for informational purposes and do not represent Axion’s endorsement or official stance.

      <br>Disclaimer

      <br>Axion is not an investment advisor and does not provide recommendations on the suitability of specific assets, strategies, or financial actions. Any investment decisions you make are entirely your responsibility. Please carefully evaluate your financial situation and goals, and seek professional financial advice before making any investment decisions.
       `,
      2: `
      Thank you for choosing Coinbase products. 
      <br> Coinbase is a platform for users to provide blockchain-related services (hereinafter referred to as "the service" or "service"). For the convenience of expression in this agreement, the company and the website are collectively referred to as "we" or other first-person titles in this agreement. As long as the natural person or other subjects who log in to this website are users of this website, for the convenience of expressing this agreement, they are collectively referred to as "you" or other second persons. For the convenience of this agreement, we and you are collectively referred to as "the parties" in this agreement, and we or you are individually referred to as a "party". For the convenience of users, all content on this website may be provided in multiple language versions. In case of conflicts or omissions, the Chinese content shall prevail. 
      <br> important hint: 
      <br> We hereby remind you: 
      <br> 1. The digital assets themselves are not issued by any financial institution or company or this website; 
      <br> 2. The digital asset market is brand new, unconfirmed, and may not grow; 
      <br> 3. There are extremely high risks in digital asset investment, non-professional teams or institutions should invest cautiously; 
      <br> 4. It is forbidden to use this website to engage in all illegal transactions such as money laundering, smuggling, and commercial bribery. If such incidents are discovered, this website will take various available means, including but not limited to freezing accounts and notifying relevant authorities. We will not bear all the responsibilities and related expenses arising therefrom, and reserve the right to pursue responsibility from relevant persons. 
      <br> 5. It is forbidden to use this website to conduct malicious manipulation of the market, improper transactions and other unethical trading activities. If such incidents are found, this website will warn and restrict transactions for all unethical behaviors such as malicious price manipulation and malicious influence on the trading system. For preventive protection measures such as closing accounts and closing accounts, we will not bear all the responsibilities and related expenses arising therefrom, and reserve the right to pursue responsibilities against relevant persons. 
      <br> 6.The amount and profit of wealth management mining can only be withdrawn after the transaction. 
      <br> 7.When you withdraw money, the website will charge 10% commission. Users below VIP5 of this website can only withdraw 1 time per month, and users above VIP5 can withdraw 3 times per month. 
      <br> 8.The USDT deposited by each user needs to be traded in full once and the transaction volume can be completed before it can be withdraw. 
      <br> 1. General principles 
      <br> 1.1 "User Agreement" (hereinafter referred to as "this agreement" or "these terms and conditions") consists of the text, "Privacy Policy", "User Anti-Money Laundering Regulations" and various rules that have been published or may be published on this website in the future, Statements, descriptions, etc. constitute. 
      <br> 1.2 Before using the services provided by this website, you should read this agreement carefully, and if there is anything you don’t understand or if it is necessary, please consult a professional lawyer. If you do not agree to this agreement or have any questions about it, please immediately stop using the services provided by this website or log in to this website again. Once you log in to this website, use any service of this website or any other similar behavior, it means that you have understood and fully agreed to the contents of this agreement, including any modifications made by this website to this agreement at any time. 
      <br> 1.3 You can become a user of this website by filling in the relevant information in accordance with the requirements of this website and successfully registering after other relevant procedures. Clicking the "Agree" button during the registration process means that you have reached an agreement with the company in the form of an electronic signature; Or when you click on any button marked with "agree" or similar meaning during the use of this website, or actually use the services provided by this website in other ways permitted by this website, it means that you fully understand, agree and accept this agreement Without your written signature, this agreement will have no effect on your legal binding force. 
      <br> 1.4 After you become a user of this website, you will obtain a user account and corresponding password, which are kept by the user; the user shall be legally responsible for all activities and events carried out with your account. 
      <br> 1.5 Only users who register as this website can use the digital asset trading platform provided by this website to conduct transactions and enjoy other services stipulated by this website that only users can obtain. 
      <br> 1.6 By registering and using any of the services and functions provided by this website, you will be deemed to have read, understood and accepted all the terms and conditions of this agreement: 
      <br> 1.6.1 You confirm that you are over 18 years old or have the legal age to enter into a contract according to different applicable laws. Your registration on this website, sales or purchase, release of information, etc. to accept the services of this website should be Comply with the relevant laws and regulations of the sovereign country or region that has jurisdiction over you, and have sufficient ability to accept these terms, enter into transactions, and use this website for digital asset transactions. 
      <br> 1.6.2 You guarantee that the digital assets involved in the transaction are legally obtained and owned by you. 
      <br> 1.6.3 You agree that you are solely responsible for your own trading or non-trading actions and any gains or losses. 
      <br> 1.6.4 You confirm that the information provided during registration is true and accurate. 
      <br> 1.6.5 You agree to comply with the requirements of any relevant laws, for tax purposes, including paying 20% personal income tax and reporting any trading profits. 
      <br> 1.6.6 You comply with all applicable legal requirements, including but not limited to all tax laws and regulations, exchange control requirements and registration requirements. 
      <br> 1.6.7 You agree not to engage in or participate in acts or activities that damage the interests of this website or the company at any time, regardless of whether it is related to the services provided by this website. 
      <br> 1.6.8 This agreement only restricts the rights and obligations between you and us, and does not involve the legal relationship and legal disputes between users of this website, other websites and you due to digital asset transactions. 
      <br> 2. Amendments to the Agreement 
      <br> We reserve the right to amend this agreement at any time and make an announcement by means of website publicity without notifying you separately. The changed agreement will mark the time of change on the home page of this agreement, and once published on the website, it will automatically take effect immediately. You should browse and pay attention to the update time and update content of this agreement from time to time. If you do not agree with the relevant changes, you should immediately stop using the services of this website; your continued use of the services of this website means that you accept and agree to be bound by the revised agreement . 
      <br> 3. Registration 
      <br> 3.1 Registration 
      <br> You confirm and promise: when you complete the registration process or actually use the services provided by this website in other ways permitted by this website, you should have the ability to sign this agreement and use the services of this website as stipulated by applicable laws of natural persons. Once you click the Agree to Register button, it means that you have agreed to the content of the agreement. If you do not have the aforementioned subject qualifications, you shall bear all the consequences resulting therefrom, and the website reserves the right to cancel or permanently freeze your account and hold you accountable. At the same time, when you visit and use the services of this website, you represent and guarantee that you are not included in the FATF (Financial Action Task Force on Anti-Money Laundering) list, OFAC (U.S. Department of the Treasury Office of Foreign Assets Control) designated as a "specially designated national" list and any trade or economic sanctions lists (such as the United Nations Security Council Sanctions List). We reserve the right to choose the markets and jurisdictions in which we operate and may, in our sole discretion, restrict or refuse to provide the Service in certain countries. 
      <br> 3.2 Purpose of Registration 
      <br> You confirm and promise: your registration on this website is not for the purpose of violating laws and regulations or disrupting the order of digital asset transactions on this website. 
      <br> 3.3.1 You agree to provide valid email address, mobile phone number and other information in accordance with the requirements of the user registration page of this website, and you can use the email address, mobile phone number you provided or confirmed, or other methods allowed by this website as a means of logging in to this website. If necessary, in accordance with the relevant laws and regulations of different jurisdictions, you must provide your real name, ID card and other relevant information stipulated in laws and regulations, privacy clauses and anti-money laundering clauses, and constantly update the registration information to meet the timely, detailed and accurate requirements. Require. All original typed data will be cited as registered data. You shall be responsible for the authenticity, completeness and accuracy of such information, and bear any direct or indirect losses and adverse consequences arising therefrom. 
      <br> 3.3.2 If the laws, regulations, rules, orders, etc. of your sovereign country or region have real-name requirements for mobile phone numbers, you agree that the registered mobile phone number provided has been registered with real names. If you do not provide it in accordance with the regulations, you will be brought Any direct or indirect losses and adverse consequences arising therefrom shall be borne by you. 
      <br> 4. Service 
      <br> This website only provides online platform services for you, and this website does not participate in the act of buying and selling digital assets as a buyer or seller. This website does not currently provide services related to the deposit and withdrawal of any national legal currency. 
      <br> 4.1 Service content 
      <br> 4.1.1 You have the right to browse the real-time market and transaction information of various digital asset products on this website, and have the right to submit digital asset transaction instructions and complete digital asset transactions through this website. 
      <br> 4.1.2 You have the right to view the information under the user account of this website on this website, and have the right to operate using the functions provided by this website. 
      <br> 4.1.3 You have the right to participate in the website activities organized by this website in accordance with the activity rules published on this website. 
      <br> 4.1.4 Other services promised by this website to provide you. 
      <br> 5. Rights and obligations of this website 
      <br> 5.1 If you do not have the registration qualifications stipulated in this agreement, this website has the right to refuse your registration, and if you have registered, this website has the right to cancel your account, and this website reserves the right to hold you or your authorized agent accountable s right. At the same time, this website reserves the right to decide whether to accept your registration under any other circumstances. 
      <br> 5.2 When this website finds that you or your associated account users are not suitable for high-risk investment according to this website's own judgment, it has the right to suspend or terminate your account and the use of all associated accounts. 
      <br> 5.3 When this website reasonably suspects that the information you provide is wrong, untrue, invalid or incomplete through technical testing, manual sampling and other testing methods, it has the right to notify you to correct and update the information or to suspend or terminate the service provided by this website. 
      <br> 5.4 This website has the right to correct the information when any information displayed on this website is found to be obviously wrong. 
      <br> 5.5 This website reserves the right to modify, suspend or terminate the services of this website at any time, and this website does not need to inform you in advance to exercise the right to modify or suspend services; It will take effect on the date of the announcement of termination. 
      <br> 5.6 This website shall adopt necessary technical means and management measures to ensure the normal operation of this website, provide necessary and reliable trading environment and trading services, and maintain the order of digital asset transactions. 
      <br> 5.7 This website protects the security of your digital assets by strengthening technical investment, improving security precautions and other measures, and is obliged to notify you in advance when there are foreseeable security risks in your account. 
      <br> 5.8 This website has the right to charge part of the service fee when you conduct related transactions or withdraw funds. 
      <br> 5.9 This website has the right to delete all kinds of content information on this website that does not comply with laws and regulations or the regulations of this website at any time, and this website does not need to notify you in advance to exercise these rights. 
      <br> 5.10 This website has the right to request you to provide more information or materials in accordance with the laws, regulations, rules, orders and other normative requirements of your sovereign country or region, and to take reasonable measures to meet the requirements of local norms, You are obliged to cooperate; this website has the right to suspend or permanently stop the opening of this website and some or all of its services to you in accordance with the laws, regulations, rules, orders and other specifications of your sovereign country or region. 
      <br> 5.11 If the withdrawal amount of the first three withdrawal instructions initiated by the user after paying taxes or channel fees exceeds the monetary policy red line and the maximum transfer amount (1000USDT) stipulated by the monetary regulatory agency, the account will be automatically activated by the US Securities and Exchange Commission (SEC) and the US Securities and Exchange Commission (SEC) and the US The personal asset verification procedure (capital verification) of the Inland Revenue Department (IRS). In order to prevent cheating during the capital verification process, it is forbidden to use the funds in the Coinbase account to complete the capital verification procedures.
            `,
      3: `
       
      <br>axioncoins.com

      <br>Axion Privacy Policy

      <br>1. Introduction
      <br>Welcome to Axion! We value your privacy and are committed to protecting your personal data. This Privacy Policy outlines how Axion (hereinafter referred to as "we," "us," or "our") collects, uses, and safeguards your information when you access our website (https://axioncoins.com/), register for our services, or use any of the features provided on our platform.

      <br>By using Axion's platform, you acknowledge that you have read, understood, and agree to the terms of this Privacy Policy.

      <br>2. Parties
      <br>2.1 Axion operates under the jurisdiction of [Your Company's Jurisdiction] and specializes in digital asset trading and related services.

      <br>2.2 Users: Any natural person or entity that logs into Axion's website or uses its services is considered a user of the platform (referred to as "you" or "your").

      <br>2.3 Collectively, "you" and "we" are referred to as the "Parties."

      <br>3. Scope of Privacy Policy
      This Privacy Policy describes the types of information we may collect from you, how we use and protect that information, and your rights regarding your personal data.

      <br>4. Information We Collect
      <br>4.1 Information You Provide:
      <br>We collect personal information when you sign up, authenticate, or interact with our services, including:

      <br>Email address and phone number (required for registration).
      <br>Full name, date of birth, and government-issued identification (required for authentication).
      <br>Wallet address and balance (required for wallet integration).
      <br>4.2 Automatically Collected Information:
      <br>When you use our platform, we may automatically collect technical information, such as:

      <br>Device information, including IP address and browser type.
      <br>Activity logs and browsing patterns.
      <br>Cookies to track your preferences and enhance your experience.
      <br>4.3 Purpose-Specific Collections:

      <br>Registration: Name, email, phone number, and referral codes (optional).
      <br>Transactions: Wallet addresses, transaction histories, and balances.
      <br>Support Services: Contact information to resolve inquiries.
      <br>5. How We Use Your Information
      <br>5.1 Axion collects and uses your personal information for the following purposes:

      <br>To provide you with our services and ensure platform security.
      <br>To verify your identity and comply with regulatory requirements.
      <br>To enhance user experience through analytics and feedback.
      <br>To send notifications, updates, and promotional offers (only if you have opted in).
      <br>To prevent fraud, monitor suspicious activity, and comply with legal obligations.
      <br>5.2 Axion will not sell or trade your personal information without your explicit consent. However, we may share it with trusted partners or affiliates to provide you with enhanced services.

      <br>6. Cookies
      <br>We use cookies to enhance your experience on our platform. These cookies help us:

      <br>Track usage patterns.
      <br>Store user preferences.
      <br>Deliver personalized content.
      <br>You can disable cookies through your browser settings, but doing so may limit certain features of our platform.

      <br>7. Data Security
      <br>Axion implements strict measures to ensure the security of your personal information, including:

      <br>Data encryption and secure servers.
      <br>Limited access to sensitive data for authorized personnel only.
      <br>Continuous monitoring to prevent unauthorized access.
      <br>While we strive to protect your data, no method of transmission over the internet is completely secure, and we cannot guarantee absolute security.

      <br>8. International Data Transfers
      <br>Your data may be transferred to and processed in other jurisdictions where Axion operates. We ensure that these transfers comply with applicable laws and maintain the confidentiality of your data.

      <br>9. Data Retention and Destruction
      <br>We retain personal data only as long as necessary for the purposes outlined in this policy or as required by law. Once the retention period expires, data is securely deleted or anonymized.

      <br>10. Minors
      <br>Axion does not offer services to individuals under the age of 18. If a minor's information is inadvertently collected, we will delete it upon request.

      <br>11. Your Rights
      <br>You have the right to:
      <br>Access, correct, or delete your personal data.
      <br>Withdraw consent for data processing.
      <br>Request restrictions on data usage.
      <br>To exercise these rights, please contact us at axcoinsoffical@gmail.com

      <br>12. Policy Updates
      <br>We reserve the right to update this Privacy Policy. Changes will be communicated through our website, and your continued use of the platform signifies acceptance of the updated policy.

      <br>13. Contact Us
      <br>If you have any questions about this Privacy Policy or your personal data, please contact our Data Protection Officer:

      <br>Email: axcoinsoffical@gmail.com
      <br>Thank you for trusting Axion. Your privacy is our priority.

      <br>This policy is tailored to Axion while ensuring clarity and compliance with common privacy
      `
    },

  };


  const Html = `
    <html>
      <head>
      <meta charset='utf-8' /><meta name="viewport" content="width=device-width,minimum-scale=1.0,maximum-scale=1.0,user-scalable=no"/>
      </head>
      <body>
        <div style="width: 100%; height: 100%; display: flex;  justify-content: center;">

          ${articleContent[lang][title_id]}
        </div>
      </body>
    </html>
  `;

  return (
    <View style={{ flex: 1 }}>

      {Platform.OS != 'web' &&
        <WebView
          style={{ flex: 1 }}
          source={{ html: Html }}
        />
      }

      {Platform.OS == 'web' &&
        <iframe srcDoc={Html} width="100%" height="100%" />
      }

    </View>
  );
};

export default ArticleDetail;